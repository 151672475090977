import React, { useEffect } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import { useTranslation, Trans } from "react-i18next";

import "video-react/dist/video-react.css";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function Fitur() {
  const { t } = useTranslation();
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  const [videofnb, setVideofnb] = React.useState("https://www.youtube.com/embed/tZ527ZKh-Mc")
  const [videodist, setVideodist] = React.useState("https://www.youtube.com/embed/WtFIzICmLhA")
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [colorfnb, setcolorfnb] = React.useState("#f0f8ff")
  const [tampungidfnb, settampungidfnb] = React.useState(0)
  const [tampungiddist, settampungiddist] = React.useState(0)
  const [colordist, setcolordist] = React.useState("#f0f8ff")

  function onclickbuttonfnb(index, element) {
    setVideofnb(videoytfnb[index].video)
    setcolorfnb('#f0f8ff')
    // setcolordist('#ffff')
    settampungidfnb(index)
  }
  function onclickbuttondist(index, element) {
    setVideodist(videoytdist[index].video)
    setcolordist('#f0f8ff')
    // setcolorfnb('#ffff')

    settampungiddist(index)

  }
  const videoytfnb = [
    {
      "id": 0,
      "video": "https://www.youtube.com/embed/tZ527ZKh-Mc",
      "nama": "Layout Meja",
      "description": "",
    },
    {
      "id": 1,
      "video": "https://www.youtube.com/embed/ZexN2C6FtIA",
      "nama": "Pindah atau Gabung Meja dan Split Bill",
      "description": "",
    },
    {
      "id": 2,
      "video": "https://www.youtube.com/embed/Kg9VoKIfzow",
      "nama": "Promo dan Diskon",
      "description": "",
    },
    {
      "id": 3,
      "video": "https://www.youtube.com/embed/wgINKob-YYc",
      "nama": "Resep",
      "description": "",
    },
  ]
  const videoytdist = [
    {
      "id": 0,
      "video": "https://www.youtube.com/embed/WtFIzICmLhA",
      "nama": "Stok (AI, AO, Stok Opname)",
      "description": "",
    },
    {
      "id": 1,
      "video": "https://www.youtube.com/embed/NvNW2RvKhXs",
      "nama": "Terima Barang (RC)",
      "description": "",
    },
    {
      "id": 2,
      "video": "https://www.youtube.com/embed/X4kRR4rQqBg",
      "nama": "PO Request (POR)",
      "description": "",
    },
    {
      "id": 3,
      "video": "https://www.youtube.com/embed/sfIad9AFglI",
      "nama": "Penjualan",
      "description": "",
    },
  ]
  const listItemsfnb = videoytfnb.map(
    (element, index) => {
      return (
        tampungidfnb == index ?
          <Button key={index} onClick={() => onclickbuttonfnb(index, element)} style={{ width: '100%',height:'60%', textAlign: 'right', borderColor: 'red', backgroundColor: colorfnb, boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', borderRadius: 5}}>
            <div style={{ color: "#170F49", marginLeft: '4%', fontSize: 16, marginBottom: '3%',marginTop:'3%',fontFamily:"Roboto, sans-serif"}}>{element.nama}</div>
          </Button>
          :
          <Button key={index} onClick={() => onclickbuttonfnb(index, element)} style={{ width: '100%', textAlign: 'right', borderColor: 'red', backgroundColor: "#ffff", boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', borderRadius: 5 }}>
           <div style={{ color: "#170F49", marginLeft: '4%', fontSize: 16, marginBottom: '3%',marginTop:'3%',fontFamily:"Roboto, sans-serif" }}>{element.nama}</div>

          </Button>
      )
    }
  )
  const listItemsdist = videoytdist.map(
    (element, index) => {
      return (
        tampungiddist == index ?
          <Button key={index} onClick={() => onclickbuttondist(index, element)} style={{ width: '100%', textAlign: 'left', borderColor: 'red', backgroundColor: colordist, boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', borderRadius: 5 }}>
            <div style={{ color: "#170F49", marginLeft: '4%', fontSize: 16 , marginBottom: '3%',marginTop:'3%',fontFamily:"Roboto, sans-serif"}}>{element.nama}</div>
          </Button>
          :
          <Button key={index} onClick={() => onclickbuttondist(index, element)} style={{ width: '100%', textAlign: 'left', borderColor: 'red', backgroundColor: '#fff', boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', borderRadius: 5 }}>
            <div style={{ color: "#170F49", marginLeft: '4%', fontSize: 16, marginBottom: '3%',marginTop:'3%' ,fontFamily:"Roboto, sans-serif"}}>{element.nama}</div>
          </Button>
      )
    }
  )
  return (
    <>
      <div id="fitur" style={{ backgroundColor: 'rgba(53, 130, 185, 0.06)', height: '100%',marginTop:'5%' }} >
        <Container style={{ height: '100%' }}>
          <Row style={{ height: '100%' }}>
            <Col className="ml-auto mr-auto" md="10" xl="6" style={{ marginTop: '5%' }}>
              <iframe loading="lazy" width="100%" height="315" src={videofnb} title="YouTube video player" frameborder="0" allow="fullscreen"></iframe>
            </Col>
            <Col className="ml-auto mr-auto" md="10" xl="6" style={{ height: 500 }} >
              <div className="title" style={{ justifyContent: 'flex-end', textAlign: 'right', color: 'rgba(53, 130, 185, 1)', fontSize: 24 , fontWeight:800}}>{"Fitur"}</div>
              <div style={{ justifyContent: 'flex-end', textAlign: 'right', fontSize: 36, color: 'rgba(46, 45, 86, 1)' ,fontFamily:"Roboto, sans-serif"}}>{"Food & Beverages"}</div>
              <div style={{ justifyContent: 'flex-end', textAlign: 'right', fontSize: 18, color: 'rgba(95, 94, 121, 1)',fontFamily:"Roboto, sans-serif" }}>{"Sistem kasir digital yang mencatat semua transaksi secara cepat, mudah, transparan dan report secara real time."}</div>
              <div  style={{ height: '40%', overflowY: 'scroll', marginTop: '5%' }}>
                <Container>
                  {listItemsfnb}
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: '#ffff',marginTop:'2%' }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="6" style={{ height: 500 }}>
              <div className="title" style={{ justifyContent: 'flex-start', textAlign: 'left', color: 'rgba(53, 130, 185, 1)', fontSize: 24 , fontWeight:800}}>{"Fitur"}</div>
              <div style={{ justifyContent: 'flex-start', textAlign: 'left', fontSize: 36, color: 'rgba(46, 45, 86, 1)',fontFamily:"Roboto, sans-serif" }}>{"Distribution, Retail & Service"}</div>
              <div style={{ justifyContent: 'flex-start', textAlign: 'left', fontSize: 18, color: 'rgba(95, 94, 121, 1)' ,fontFamily:"Roboto, sans-serif"}}>{"Kompleksitas bisnis proses di lapangan dapat dipermudah dengan solusi dari URUZ."}</div>
              <div style={{ height: '40%', overflowY: 'scroll', marginTop: '5%' }}>
                <Container>
                  {listItemsdist}
                </Container>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="10" xl="6" style={{ marginTop: '5%' }}>
                <iframe loading="lazy" width="100%" height="315" src={videodist} title="YouTube video player" frameborder="0" allow="fullscreen"></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Fitur;
