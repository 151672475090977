import React from 'react';
import IndexNavbar from "components/Navbars/IndexNavbar.js?vers=1";
import Contact from "../index-sections/Contact.js";
import Footer from "../../components/Footers/Footer";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom"
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const PrivacyPolicy = () => {
  let history = useHistory()
  const { i18n, t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [text, setText] = React.useState("#ffff")

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());

    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
{collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar 
      className={"fixed-top"} expand="lg" 
      style={{ 
        backgroundColor: "#296CA2" 
        }}>
        <Container>
          <div style={{ paddingRight: windowDimensions.width > 500 ? 150 : 0,}} className="navbar-translate">
            <Link to="/">
              <NavbarBrand
                // href="index"
                // target="_blank"
                // id="navbar-brand"
              >
                <img
                  alt="..."
                  className="n-logo"
                  src={require("../../assets/image/bg-img/Logonew.png").default}
                style={{width:150}}></img>
              </NavbarBrand>
            </Link>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span style={{ background:  '#fff' }} className="navbar-toggler-bar top-bar"></span>
              <span style={{ background:  '#fff' }} className="navbar-toggler-bar middle-bar"></span>
              <span style={{ background:  '#fff' }} className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-center"
            isOpen={collapseOpen}
            navbar
          >
              <Nav navbar style={{ width: '100%', alignItems: 'center'}}>
                <Link style={{ width: '80%'}} to="/">
     
                </Link>

                <Link style={{ width: '80%'}} to="/">
 
                </Link>

            

                <NavItem style={{ width: '80%',textAlign:'center'}}>
      
                </NavItem>
                <NavItem style={{ width: '80%',paddingRight:windowDimensions.width>500?150:0 ,textAlign:'center'}}>

                </NavItem>
                <NavItem style={{ width: '100%' ,textAlign:'center'}} >
                 <Link to="/website/page-login">
                  <NavLink
                    style={{ textAlign: 'center',  borderRadius: '5px', }}
                    >
                    <p style={{color: windowDimensions.width > 800 ? "#ffff":"black" }}>{t("REQUEST DEMO")}</p>
                  </NavLink>
                  </Link>
                </NavItem>
                <NavItem style={{ width: '100%'}} >
                  <NavLink
                    href="http://bo.uruz.id"
                    target="_blank"
                    style={{ textAlign: 'center', border: '1px solid #3582B9', backgroundColor:"#ffff", borderRadius: '5px'}}
                    >
                    <p style={{ color: "#3582B9", fontWeight:"bold" }}>{"Login" }</p>
                  </NavLink>
                </NavItem>
       
              </Nav>
          </Collapse>
        </Container>
      </Navbar>      
      <Container style={{marginTop:windowDimensions.width > 800 ? '8%':"30%"}}>
      <h1>Privacy Policy for Uruz Android App</h1>

<h3>Effective Date: [2023-07-11]</h3>

<p>
  Thank you for using Uruz, a commercial Android application developed by Uruz Teknologi Solusi ("we," "us," or "our"). This Privacy Policy is intended to inform you about the collection, use, and disclosure of personal information when you use our Android app.
</p>

<h2>Information We Collect</h2>
<h3>1.1 Personal Information</h3>
<p>
  We may collect and process the following types of personal information from you:
</p>
<ul>
  <li>
  Face Data: We collect face data for the purpose of HR module features, such as facial recognition for attendance tracking or access control.
  </li>
  <li>
    Location Data: We collect location data for HR module features, such as tracking employee location for attendance or geofencing purposes.
  </li>
  <li>
    Transaction Data: We collect transaction data for the Point of Sale (POS) module, including information related to purchases, sales, and payments made through the app.
  </li>
  <li>
    Bluetooth Permission: We may request Bluetooth permission to facilitate printing functionality through compatible devices.
  </li>
  <li>
    Camera Permission: We may request Camera permission for validating attendance.
  </li>
  <li>
  Image Data : We collect image data for the Point of sales (POS) and HR module features, including information related to stock in proof, stock out proof, payment proof, marketing and employee.  </li>
</ul>

<h3>1.2 Non-Personal Information</h3>
<p>
  We may also collect non-personal information that does not directly identify you. This information may include technical details about your device, such as device type, operating system version, language preference, and application usage statistics.
</p>

<h2>Use of Information</h2>
<p>
  We use the collected information for the following purposes:
</p>
<ul>
  <li>
    To provide and maintain the functionality of the HR module, including attendance tracking and access control.
  </li>
  <li>
    To process transactions and provide the Point of Sale (POS) module features.
  </li>
  <li>
    To facilitate printing functionality through Bluetooth-enabled devices.
  </li>
  <li>
    To improve our app and services, including analyzing usage patterns and enhancing user experience.
  </li>
  <li>
    To respond to your inquiries, support requests, or feedback.
  </li>
  <li>
    To comply with legal obligations or enforce our rights and policies.
  </li>
</ul>

<h2>Data Sharing and Disclosure</h2>
<p>
  We may share your personal information in the following circumstances:
</p>
<ul>
  <li>
    With third-party service providers: We may engage trusted third-party companies or individuals to facilitate app functionality or provide services on our behalf (e.g., Firebase for data storage and processing). These third parties have limited access to your information and are bound by confidentiality obligations.
  </li>
  <li>
    With your consent: We may share your personal information with third parties if you provide us with your explicit consent to do so.
  </li>
  <li>
    For legal reasons: We may disclose your personal information if required to do so by law or in response to valid requests from public authorities (e.g., government agencies, law enforcement) in compliance with applicable laws.
  </li>
</ul>

<h2>Data Security</h2>
<p>
  We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
</p>

<h2>Third-Party Links and Services</h2>
<p>
  Our app may contain links to third-party websites or services. This Privacy Policy does not cover the practices of these third parties, and we encourage you to review their privacy policies before providing any personal information to them.
</p>

<h2>Children's Privacy</h2>
<p>
  Uruz is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected personal information from a child, please contact us, and we will take appropriate measures to remove such information from our systems.
</p>

<h2>Changes to this Privacy Policy</h2>
<p>
  We may update this Privacy Policy from time to time. The updated version will be effective as of the date specified at the beginning of this policy. We encourage you to review this Privacy Policy periodically for any changes.
</p>

<h2>Contact Us</h2>
<p>
  If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at support@uruz.id
</p>

<p>
  By using the Uruz Android app, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your personal information as described herein.
</p>
      </Container>
     
      <Footer/>
    </>
  );
};

export default PrivacyPolicy;
