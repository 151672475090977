import React from "react";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom"


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function IndexNavbar({ onProduct }) {
  let history = useHistory()
  const { i18n, t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [text, setText] = React.useState("#ffff")
  const currentPath = window.location.pathname;

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());

    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [scrollDown, setScrollDown] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setScrollDown(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
        setText("black")

      }
    };
    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
       <Navbar
      className={`fixed-top ${scrollDown ? 'scroll-down' : ''}`}
      expand="lg"
      style={{
        backgroundColor: scrollDown ? 'white' : '#1D578E',
      }}
    >
        <Container>
          <div style={{ paddingRight: windowDimensions.width >= 1024 ? 70 : 0, }} className="navbar-translate">
            <Link to="/">
              <NavbarBrand
              // href="index"
              // target="_blank"
              // id="navbar-brand"
              >
                <img
                  alt="..."
                  className="n-logo"
                  src={!scrollDown ? require("../../assets/image/bg-img/Logonew.png").default : require("../../assets/image/UruzBlue.png").default}
                  style={{ width: 150,height: '60px' }}></img>
              </NavbarBrand>
            </Link>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span style={{ background:scrollDown ? 'black' : '#fff' }} className="navbar-toggler-bar top-bar"></span>
              <span style={{ background:scrollDown ? 'black' : '#fff' }} className="navbar-toggler-bar middle-bar"></span>
              <span style={{ background:scrollDown ? 'black' : '#fff' }} className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-center"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar style={{ width: '100%', alignItems: 'center' }}>
              <Link style={{ width: '80%' }} to="/">
                <NavItem style={{ width: '100%', borderRadius: 5, textAlign: 'center' }}>
                  <NavLink
                  // onClick={e => {
                  //   let hero = document.getElementById("beranda");
                  //   e.preventDefault();  // Stop Page Reloading
                  //   hero && hero.scrollIntoView();
                  // }}
                  >
                    <p style={{ color: scrollDown || windowDimensions.width < 1024 ? "black" : "#ffff", fontFamily: "Roboto, sans-serif", fontSize: '14px', fontWeight: '700' }}>{t("Home")}</p>
                  </NavLink>
                </NavItem>
              </Link>

              <Link style={{ width: '80%' }} >
                <NavItem style={{ width: '100%', borderRadius: 5, textAlign: 'center' }}>
                  <NavLink
                    onClick={e => {
                      if (currentPath === '/website/page-login') {
                        history.push('/website/#modul')
                      }
                      else {
                        let hero = document.getElementById("modul");
                        e.preventDefault();
                        hero && window.scrollTo({
                          top: hero.offsetTop - 100,
                          behavior: 'smooth'
                        });

                      }
                    }}
                    href="#modul"
                  >
                    <p style={{ color: scrollDown || windowDimensions.width < 1024 ? "black" : "#ffff", fontFamily: "Roboto, sans-serif", fontSize: '14px', fontWeight: '700' }}>{t("MODUL")}</p>
                  </NavLink>
                </NavItem>
              </Link>
              <NavItem style={{ width: '80%', textAlign: 'center' }}>
                <NavLink
                  onClick={e => {
                    let hero = document.getElementById("fitur");
                    e.preventDefault();  // Stop Page Reloading
                    hero && window.scrollTo({
                      top: hero.offsetTop - 100,
                      behavior: 'smooth'
                    });
                  }}
                  href="#fitur"
                >
                  <p style={{ color: scrollDown || windowDimensions.width < 1024 ? "black" : "#ffff", fontFamily: "Roboto, sans-serif", fontSize: '14px', fontWeight: '700' }}>{"Produk"}</p>
                </NavLink>
              </NavItem>
              <NavItem style={{ width: '80%', paddingRight: windowDimensions.width >= 1024 ? 70 : 0, textAlign: 'center' }}>
                <NavLink
                  onClick={e => {
                    let hero = document.getElementById("about");
                    e.preventDefault();  // Stop Page Reloading
                    hero && window.scrollTo({
                      top: hero.offsetTop - 100,
                      behavior: 'smooth'
                    });
                  }}
                  href="#about"
                >
                  <p style={{ color: scrollDown || windowDimensions.width < 1024 ? "black" : "#ffff", fontFamily: "Roboto, sans-serif", fontSize: '14px', fontWeight: '700' }}>{"FAQ"}</p>
                </NavLink>
              </NavItem>
              <NavItem style={{ width: '100%', textAlign: 'center' }} >
                <Link to="/website/page-login">
                  <NavLink
                    style={{ textAlign: 'center', borderRadius: '5px', }}
                  >
                    <p style={{ color: scrollDown || windowDimensions.width < 1024 ? "black" : "#ffff", fontFamily: "Roboto, sans-serif", fontSize: '14px', fontWeight: '700' }}>{t("REQUEST DEMO")}</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem style={{ width: '100%' }} >
                <NavLink
                  href="http://bo.uruz.id"
                  target="_blank"
                  style={{ textAlign: 'center', border: '1px solid #3582B9', backgroundColor: "#ffff", borderRadius: '5px' }}
                >
                  <p style={{ color: "#3582B9", fontWeight: "bold", fontFamily: "Roboto, sans-serif", fontSize: '14px' }}>{"Login"}</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
