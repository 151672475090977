/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
// import backgroundImage from "assets/image/ImageBackground2.jpg"
// import groupOne from "assets/image/bg-img/Group-1.png"
// import ImgMainRight from "assets/image/bg-img/VectorMainLeft.png"
// import ImgMainLeft from "assets/image/bg-img/VectorMainLefts.png"
import ImageUtama from "assets/image/bg-img/uruzheader2.webp"
// import ImageKiri from "assets/image/bg-img/Ellipse1.png"
// import ImageKanan from "assets/image/bg-img/Ellipse2.png"
// import FB from "assets/image/bg-img/fb.png"
// import IG from "assets/image/bg-img/ig.png"
// import YT from "assets/image/bg-img/yt.png"
// import facebook from "assets/image/bg-img/facebook.png"
// import youtube from "assets/image/bg-img/youtube.png"
// import instagram from "assets/image/bg-img/instagram.png"
import BackgroundHeader from "assets/image/bg-img/bgheader.webp"
import './Header.css';
// import backgroundImage from "assets/image/Header.jpg"
import { language } from "../Language/index"

import { useTranslation, Trans } from "react-i18next";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function IndexHeader() {
  const { t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [isVisible, setIsVisible] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const moveDistance = Math.min(scrollPosition/2 , 100);
  React.useEffect(() => {
    // Trigger the animation after the component mounts
    setIsVisible(true);
  }, []);
  
  return (
    <>
      <div
        id="beranda"
        class="bg_image"
        style={{
          backgroundImage: 'url(' + BackgroundHeader + ')',
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: 90


        }}>
          <div
            style={{
              // border : "solid 1px #3582B9",
              borderRadius: 16,
              zIndex: 15,
              marginTop: windowDimensions.width>500? '8%':0
              // backgroundColor: "rgba(255, 255, 255, 0.5)"

            }}
          >
            <Container style={{ marginBottom: '5%' }}>
              <Row>
                <Col className="ml-auto mr-auto" md="10" xl="5" style={{paddingTop: windowDimensions.width>500?'3%':0,marginRight:'3%'}}>
                  <div className={`animated-componentslidein ${isVisible ? 'visible' : ''}`} style={{ color: "#ffff", fontSize:windowDimensions.width>500?'40px' : '48px',fontFamily:"Roboto, sans-serif" }}><strong>{t("Mengelola Pertumbuhan Bisnis")}</strong></div>
                  <p className={`animated-componentslidein ${isVisible ? 'visible' : ''}`} style={{
                    fontSize: windowDimensions.width>500? '24px' :'20px',
                    fontFamily: "Roboto, sans-serif",
                    color: "#ffff"
                  }}>{t("Kembangkan proses bisnis anda dengan sistem kami yang berbasis cloud untuk solusi yang lebih cepat dan juga mudah digunakan sesuai dengan kebutuhan anda.")}</p>
                </Col>

                <Col className="ml-auto mr-auto" md="10" xl="7" >
                  <img style={{ transform: `translateY(${moveDistance}px)`,width: '100%' }} className={`animated-componentslideout ${isVisible ? 'visible' : ''}`}   src={ImageUtama}></img>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

    </>
  );
}

export default IndexHeader;

