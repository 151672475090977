import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js?vers=1";
import IndexHeader from "components/Headers/IndexHeader.js?vers=1";

// sections for this page
import KenapaUruz from "./index-sections/KenapaUruz.js?vers=1";
import Modul from "./index-sections/Modul?vers=1"
import Distribution from "./index-sections/Distribution.js?vers=1";
import Fitur from "./index-sections/Fitur.js?vers=1";
import Client from "./index-sections/Client.js";
import About from "./index-sections/About.js?vers=1";
import Contact from "./index-sections/Contact.js?vers=1";
import Footer from "components/Footers/Footer.js?vers=1";
import Testimoni from "./index-sections/Testimoni.js?vers=1";

import { useHistory } from "react-router-dom"
import wa from "assets/image/bg-img/wa.png"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function Index() {
  let history = useHistory()
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  const handleWhatsAppButtonClick = () => {
    const phoneNumber = '62817392286';
    const defaultText = encodeURIComponent('Saya ingin menggunakan URUZ');
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${defaultText}`;
    window.open(url, 'Saya Ingin Menggunakan URUZ');
  };
  function onProduct(params) {
    history.push('/website/product-page', {
      params
    })
  }
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = window.location.hash;
  
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      section && window.scrollTo({
        top: section.offsetTop - 100,
        behavior: 'smooth'
      });
    };
  
    if (hash === '#beranda' || urlParams.get('scrollTo') === 'beranda') {
      setTimeout(() => scrollToSection("beranda"), 0);
    }
    if (hash === '#modul' || urlParams.get('scrollTo') === 'modul') {
      setTimeout(() => scrollToSection("modul"), 0);
    }
    if (hash === '#fitur' || urlParams.get('scrollTo') === 'fitur') {
      setTimeout(() => scrollToSection("fitur"), 0);
    }
    if (hash === '#about' || urlParams.get('scrollTo') === 'about') {
      setTimeout(() => scrollToSection("about"), 0);
    }
  }, []);
  

  return (
    <>
      <div>
        <IndexNavbar onProduct={onProduct} />
        <IndexHeader />

        {/* <div style={{ margin: '0 20px'}}> */}
        <KenapaUruz />
        {/* <CompleteExamples /> */}
        <Modul />
        {/* <Distribution/> */}
        <Fitur />

        <Client />
        <About />
        <Testimoni/>

        <Contact />
        <Footer />
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="tooltip-whatsapp">Chat with Us on Whatsapp</Tooltip>}>
          <button
            style={{
              width: '55px',
              height: '55px',
              position: 'fixed',
              right: '30px', // Adjust the distance from the right edge of the screen
              bottom: '30px', // Adjust the distance from the bottom edge of the screen
              zIndex: '9999', // Adjust the z-index as necessary to ensure it's above other content
              borderRadius: '50%',
              backgroundColor: '#25D366', // WhatsApp brand color
              color: '#fff', // Text color is white
              cursor: 'pointer',
              fontSize: '20px',
              border: 'none',
              outline: 'none',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
            onClick={handleWhatsAppButtonClick}
          >
            <img style={{ width: '35px' }} src={wa} />
          </button>
        </OverlayTrigger>

      </div>
      {/* </div> */}
    </>
  );
}

export default Index;
