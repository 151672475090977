import React from "react";


function LogoProduct() {
    return (
        <svg width="150" height="52" viewBox="0 0 200 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.0156 44.0169V22.0711H93.0584C93.9969 22.0711 94.7851 21.7126 95.4983 21.0355C96.174 20.3186 96.5118 19.4424 96.5118 18.4068V14.7426H68.9224C66.9705 14.7426 65.3564 14.0256 64.0051 12.6316C62.6913 11.1978 62.0156 9.4453 62.0156 7.41402H103.419V22.0711C103.419 24.1422 102.743 25.8549 101.354 27.2887C100.003 28.6827 98.3886 29.3996 96.5118 29.3996H89.6051L103.419 44.0567H93.0584L79.245 29.3996H68.8848V44.0567H62.0156V44.0169Z" fill="#07244E"/>
            <path d="M110.288 36.7255V7.45114H117.195V36.7255H110.288ZM117.195 44.0142C115.243 44.0142 113.629 43.2973 112.278 41.9032C110.964 40.4694 110.288 38.7169 110.288 36.6856H151.691C151.691 38.7567 151.016 40.4694 149.627 41.9032C148.275 43.2973 146.661 44.0142 144.785 44.0142H117.195ZM144.785 38.2788V7.45114H151.691V36.7255L144.785 38.2788Z" fill="#07244E"/>
            <path d="M158.597 44.0168L186.186 14.7425H165.504C163.552 14.7425 161.938 14.0256 160.586 12.6316C159.273 11.1977 158.597 9.44527 158.597 7.41399H200V11.0783L175.826 36.7281H193.056C194.97 36.7281 196.584 37.4451 197.898 38.9187C199.249 40.3526 199.962 42.0652 199.962 44.0567H158.597V44.0168Z" fill="#07244E"/>
            <path d="M42.2025 0.0653687C42.2025 0.0653687 33.043 3.73741 37.4919 12.5018C41.8099 21.0237 46.9131 25.9082 48.8432 32.9752C48.8432 32.9752 51.9509 40.25 41.6791 47.8365C38.4733 50.1922 32.7485 52.825 25.7153 49.6379V38.8643C25.7153 38.8643 33.1738 39.4185 36.6414 36.1969C39.4873 33.5641 38.9639 26.22 35.3328 20.7812C35.297 20.7276 23.0002 4.01454 42.2025 0.0653687Z" fill="#07244E"/>
            <path d="M7.08218 0C7.08218 0 16.2417 3.67204 11.7928 12.4364C7.47474 20.9583 2.37157 25.8428 0.441526 32.9098C0.441526 32.9098 -2.66617 40.1846 7.60559 47.7711C10.8114 50.1268 16.5361 52.7596 23.5693 49.5725V38.7989C23.5693 38.7989 16.1109 39.3532 12.6433 36.1315C9.79733 33.4987 10.3207 26.1546 13.9518 20.7158C13.9877 20.6622 26.2845 3.94917 7.08218 0Z" fill="#07244E"/>
        </svg>

    )
}


export default LogoProduct