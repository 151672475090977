import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import Icons from "assets/Icons/Icons";
import IconChart from "assets/Icons/IconChart";
import IconTas from "assets/Icons/IconTas";
import Background from 'assets/image/BG.png'
import ImgAbout from 'assets/image//bg-img/Group-about.png'
import { useTranslation, Trans } from "react-i18next";
// core components
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function About(children, ...props) {
  const { title, collapse } = props;
  const [isCollapse1, setIsCollapse1] = useState(collapse);
  const [isCollapse2, setIsCollapse2] = useState(collapse);
  const [isCollapse3, setIsCollapse3] = useState(collapse);
  const [isCollapse4, setIsCollapse4] = useState(collapse);
  const [isCollapse5, setIsCollapse5] = useState(collapse);

  const [icon1, setIcon1] = useState("fa fa-chevron-right");
  const [icon2, setIcon2] = useState("fa fa-chevron-right");
  const [icon3, setIcon3] = useState("fa fa-chevron-right");
  const [icon4, setIcon4] = useState("fa fa-chevron-right");
  const [icon5, setIcon5] = useState("fa fa-chevron-right");
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const toggleFaq1 = () => {
    setIsCollapse1(!isCollapse1);
    setIsCollapse2(false);
    setIcon2("fa fa-chevron-right")
    setIsCollapse3(false);
    setIcon3("fa fa-chevron-right")
    setIsCollapse4(false);
    setIcon4("fa fa-chevron-right")
    setIsCollapse5(false);
    setIcon5("fa fa-chevron-right")


    setIcon1(state => {
      return state === "fa fa-chevron-down"
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    });

  };
  const toggleFaq2 = () => {
    setIsCollapse1(false);
    setIcon1("fa fa-chevron-right")
    setIsCollapse2(!isCollapse2);
    setIsCollapse3(false);
    setIcon3("fa fa-chevron-right")
    setIsCollapse4(false);
    setIcon4("fa fa-chevron-right")
    setIsCollapse5(false);
    setIcon5("fa fa-chevron-right")

    setIcon2(state => {
      return state === "fa fa-chevron-down"
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    });
  };
  const toggleFaq3 = () => {
    setIsCollapse1(false);
    setIcon1("fa fa-chevron-right")
    setIsCollapse2(false);
    setIcon2("fa fa-chevron-right")
    setIsCollapse3(!isCollapse3);
    setIsCollapse4(false);
    setIcon4("fa fa-chevron-right")
    setIsCollapse5(false);
    setIcon5("fa fa-chevron-right")
    setIcon3(state => {
      return state === "fa fa-chevron-down"
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    });
  };
  const toggleFaq4 = () => {
    setIsCollapse1(false);
    setIcon1("fa fa-chevron-right")
    setIsCollapse2(false);
    setIcon2("fa fa-chevron-right")
    setIsCollapse3(false);
    setIcon3("fa fa-chevron-right")
    setIsCollapse4(!isCollapse4);
    setIsCollapse5(false);
    setIcon5("fa fa-chevron-right")
    setIcon4(state => {
      return state === "fa fa-chevron-down"
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    });
  };
  const toggleFaq5 = () => {
    setIsCollapse1(false);
    setIcon1("fa fa-chevron-right")
    setIsCollapse2(false);
    setIcon2("fa fa-chevron-right")
    setIsCollapse3(false);
    setIcon3("fa fa-chevron-right")
    setIsCollapse4(false);
    setIcon4("fa fa-chevron-right")
    setIsCollapse5(!isCollapse5);
    setIcon5(state => {
      return state === "fa fa-chevron-down"
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    });
  };
  useEffect(() => {
    // toggleFaq1();
  }, [collapse]);
  const { t } = useTranslation();
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());

    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleWhatsAppButtonClick = () => {
    const phoneNumber = '62817392286';
    const defaultText = encodeURIComponent('Saya ingin menggunakan URUZ');
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${defaultText}`;
    window.open(url, 'Saya Ingin Menggunakan URUZ');
  };


  return (
    <>
      <div id="about" className="section section-tabs">
        <Container>
          <h3 style={{ textAlign: 'center', color: 'rgba(53, 130, 185, 1)', marginBottom: '5%', fontWeight: 800 ,fontFamily:"Roboto, sans-serif"}}>F A Q s</h3>
          <div className="coll-panel" style={{ border: '1px solid #3582B9', margin: '1%', borderRadius: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '88%', }}>
                <h3 className="title" style={{ color: "#170F49", marginLeft: '4%', fontSize: windowDimensions.width >= 1024 ? 18 : 16,fontFamily:"Roboto, sans-serif"}}>{("Apa itu URUZ?")}</h3>
              </div>
              <div style={{ width: '12%' }}>
                <button
                  type="button"
                  onClick={() => toggleFaq1()}
                  style={{ borderRadius: '200%', width: windowDimensions.width > 500 ? 60 : 40, height: windowDimensions.width > 500 ? 60 : 40, marginTop: windowDimensions.width > 500 ? 10 : 25, border: '1px solid #3582B9', backgroundColor: '#ffff' }}
                >
                  <i className={icon1} style={{ color: '#3582B9' }} /> {title}
                </button>
              </div>


            </div>
            <Collapse isOpen={isCollapse1}>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%',marginRight:'9%',fontFamily:"Roboto, sans-serif" }}>{'Uruz adalah perusahaan konsultasi & system manajemen penyedia IT end-to-end solution dengan pengalaman 20 tahun untuk Food & Beverages(retail) dan Distribusi yang dapat dipakai oleh Principal/Distributor/Retailer/Toko).'}</p>
            </Collapse>
          </div>
          <div className="coll-panel" style={{ border: '1px solid #3582B9', margin: '1%', borderRadius: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '88%', }}>
                <h3 className="title" style={{ color: "#170F49", marginLeft: '4%', fontSize: windowDimensions.width >= 1024 ? 18 : 16 ,fontFamily:"Roboto, sans-serif"}}>{("Kenapa harus memilih URUZ?")}</h3>
              </div>
              <div style={{ width: '12%' }}>
                <button
                  type="button"
                  onClick={() => toggleFaq2()}
                  style={{ borderRadius: '200%', width: windowDimensions.width > 500 ? 60 : 40, height: windowDimensions.width > 500 ? 60 : 40, marginTop: windowDimensions.width > 500 ? 10 : 25, border: '1px solid #3582B9', backgroundColor: '#ffff' }}
                >
                  <i className={icon2} style={{ color: '#3582B9' }} /> {title}
                </button>
              </div>

            </div>
            <Collapse isOpen={isCollapse2}>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%',marginRight:'9%',fontFamily:"Roboto, sans-serif" }}>{'Karena uruz menggunakan system erp yang berbasis cloud yang dapat membantu penjualan offline maupun online toko anda dan tentu saja layanan dan fitur dapat dilihat secara realtime. Yang lebih hebatnya lagi kami memberikan fitur lengkap yang mencakup kebutuhan bisnismu dengan harga yang kompetitif.'}</p>
            </Collapse>
          </div>
          <div className="coll-panel" style={{ border: '1px solid #3582B9', margin: '1%', borderRadius: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '88%',  }}>
                <h3 className="title" style={{ color: "#170F49", marginLeft: '4%', fontSize: windowDimensions.width >= 1024 ? 18 : 16,fontFamily:"Roboto, sans-serif" }}>{("Mengapa saya harus memilih uruz POS dari sistem manual?")}</h3>
              </div>
              <div style={{ width: '12%' }}>
                <button
                  type="button"
                  onClick={() => toggleFaq3()}
                  style={{ borderRadius: '200%', width: windowDimensions.width > 500 ? 60 : 40, height: windowDimensions.width > 500 ? 60 : 40, marginTop: windowDimensions.width > 500 ? 10 : 30, border: '1px solid #3582B9', backgroundColor: '#ffff' }}
                >
                  <i className={icon3} style={{ color: '#3582B9' }} /> {title}
                </button>
              </div>

            </div>
            <Collapse isOpen={isCollapse3}>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%',marginRight:'9%',fontFamily:"Roboto, sans-serif" }}>{'Uruz menyediakan laporan aktivitas bisnis secara realtime kemudian kami juga memiliki fitur BackOffice untuk melakukan menajemen yang pengguna butuhkan seperti cek stok, penjualan dan lain lain. Dengan uruz maka bisnis anda akan dapat dikelola dengan baik dan terintegrasi sehingga mendapat hasil maksimal.'}</p>
            </Collapse>
          </div>
          <div className="coll-panel" style={{ border: '1px solid #3582B9', margin: '1%', borderRadius: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '88%' }}>
                <h3 className="title" style={{ color: "#170F49", marginLeft: '4%', fontSize: windowDimensions.width >= 1024 ? 18 : 16,fontFamily:"Roboto, sans-serif" }}>{("Apa keuntungan yang didapat menggunakan system erp uruz?")}</h3>
              </div>
              <div style={{ width: '12%' }}>
                <button
                  type="button"
                  onClick={() => toggleFaq4()}
                  style={{ borderRadius: '200%', width: windowDimensions.width > 500 ? 60 : 40, height: windowDimensions.width > 500 ? 60 : 40, marginTop: windowDimensions.width > 500 ? 10 : 30, border: '1px solid #3582B9', backgroundColor: '#ffff' }}
                >
                  <i className={icon4} style={{ color: '#3582B9' }} /> {title}
                </button>
              </div>

            </div>

            <Collapse isOpen={isCollapse4}>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%',fontFamily:"Roboto, sans-serif" }}>{'1.	Mudah digunakan '}</p>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%' ,fontFamily:"Roboto, sans-serif"}}>{'2.	Komprehensif'}</p>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%',fontFamily:"Roboto, sans-serif" }}>{'3.	Dapat disesuaikan dengan kebutuhan(Custom)'}</p>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%',fontFamily:"Roboto, sans-serif" }}>{'4.	Menguntungkan secara value'}</p>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%',fontFamily:"Roboto, sans-serif" }}>{'5.	Cepat di impelementasikan'}</p>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%',fontFamily:"Roboto, sans-serif" }}>{'6.	Memahami keseluruhan proses bisnis perusahaan'}</p>

            </Collapse>
          </div>
          <div className="coll-panel" style={{ border: '1px solid #3582B9', margin: '1%', borderRadius: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '88%',  }}>
                <h3 className="title" style={{ color: "#170F49", marginLeft: '4%', fontSize: windowDimensions.width >= 1024 ? 18 : 16,fontFamily:"Roboto, sans-serif" }}>{("Saya tertarik menggunakan URUZ system dan ingin tahu lebih lanjut?")}</h3>
              </div>
              <div style={{ width: '12%' }}>
                <button
                  type="button"
                  onClick={() => toggleFaq5()}
                  style={{ borderRadius: '200%', width: windowDimensions.width > 500 ? 60 : 40, height: windowDimensions.width > 500 ? 60 : 40, marginTop: windowDimensions.width > 500 ? 10 : 35, border: '1px solid #3582B9', backgroundColor: '#ffff' }}
                >
                  <i className={icon5} style={{ color: '#3582B9' }} /> {title}
                </button>
              </div>

            </div>
            <Collapse isOpen={isCollapse5}>
              <p style={{ color: '#6F6C90', fontWeight: "500", marginLeft: '4%', marginRight: '9%',fontFamily:"Roboto, sans-serif" }}>{'Untuk pertanyaan lebih lanjut anda dapat menghubungi pihak sales kita di nomor '}
                <a onClick={(e) => { e.preventDefault(); handleWhatsAppButtonClick() }} style={{ color: '#6F6C90', fontWeight: "500", cursor: 'pointer',fontFamily:"Roboto, sans-serif" }}>+62 817392286</a>
              </p>
            </Collapse>
          </div>

        </Container>
      </div>
    </>
  );
}

export default About;
