import React from "react";


import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Header from "./login-sections/Header";
import Contact from "./index-sections/Contact.js";
import Footer from "components/Footers/Footer";
import About from "./index-sections/About";
import { useHistory } from "react-router-dom"

function PageLogin(params) {
    let history = useHistory()
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("index-page");
          document.body.classList.remove("sidebar-collapse");
        };
    });

    function onProduct(params) {
        history.push('/website/product-page', {
          params
        })
      }
 
    return (
        <>
            <IndexNavbar onProduct={onProduct}/>
                <Header/>
                <Contact/>
            <Footer/>
        </>
    )
}


export default PageLogin