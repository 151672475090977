import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    NavLink
} from "reactstrap";
import IconEmail from "assets/Icons/IconEmail";
import IconMaps from "assets/Icons/IconMaps";
import IconPhone from "assets/Icons/IconPhone";
import IconLinkeDin from "assets/Icons/IconLinkeDin";
import IconFacebook from "assets/Icons/IconFacebook";
import IconInstagram from "assets/Icons/IconInstagram";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"
import IconsSales from "assets/Icons/IconSales";
import IconYoutube from "assets/Icons/IconYoutube";
import IconTas from "assets/Icons/IconTas";
import '../../components/Headers/Header.css';

// core components
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function Contact() {
    let history = useHistory()
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    const { i18n, t } = useTranslation();
    const [iconPills, setIconPills] = React.useState("1");
    const [pills, setPills] = React.useState("1");
    const imageClick = () => {
        console.log('Click!!!!');
    }
    const currentPath = window.location.pathname;
    const handleWhatsAppButtonClick = () => {
        const phoneNumber = '62817392286';
        const defaultText = encodeURIComponent('Saya ingin menggunakan URUZ');
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${defaultText}`;
        window.open(url, 'Saya Ingin Menggunakan URUZ');
    };
    const handleCall = () => {
        const phoneNumber = '0817392286';
        window.location.href = `tel:${phoneNumber}`;
    }
    const [showProducts, setShowProducts] = React.useState(false);
    const [showQuickLink, setQuickLink] = React.useState(false);
    const [showContact, setContact] = React.useState(false);

    const handleProductsClick = () => {
        // Toggle the state to show/hide the products
        setShowProducts(!showProducts);
    };
    const handleQuickLinkClick = () => {
        // Toggle the state to show/hide the products
        setQuickLink(!showQuickLink);
    };
    const handleContactClick = () => {
        // Toggle the state to show/hide the products
        setContact(!showContact);
    };
    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
            console.log(getWindowDimensions())
            if (getWindowDimensions().width > 1024) {
                setShowProducts(true)
                setQuickLink(true)
                setContact(true)
            }
            else{
                setShowProducts(false)
                setQuickLink(false)
                setContact(false)
            }
        }
        

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isVisible, setIsVisible] = React.useState(false);
    const [animationKey, setAnimationKey] = React.useState(0);
    React.useEffect(() => {
        // Trigger the animation after the component mounts
        setIsVisible(true);
        setShowProducts(true)
                setQuickLink(true)
                setContact(true)
    }, []);

    return (
        <>
            <div id="kontak" className="section section-tabs" style={{ backgroundColor: 'rgba(4, 41, 94, 1)' }}>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                            <Row>
                                <img
                                    loading="lazy"
                                    alt="..."
                                    className="n-logo"
                                    src={require("../../assets/image/bg-img/Logonew.png").default}
                                    style={{ width: 150, }}></img>

                            </Row>
                            <Row>
                                <div style={{ marginTop: 5, display: 'flex' }}>
                                    <div className="col" style={{ color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>
                                        #KamiYangUruz
                                    </div>
                                </div>
                            </Row>

                            {/* <div style={{ marginTop: 5, display: 'flex'}}>
                    <IconPhone/>
                    <a target="_blank" style={{ color: '#000', textDecoration: 'none'}} href="https://wa.me/628179097899">
                        <div className="col">
                            +62 817392286
                        </div>
                    </a>
                </div>
                <div style={{ marginTop: 5, display: 'flex'}}>
                    <IconMaps/>
                    <div className="col">
                    Ruko Green Mansion Blok C No.17 
                    Jl. Daan Mogot, Cengkareng, Jakarta - 11750
                    </div>
                </div> */}
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div onClick={() => {
                                    if (windowDimensions.width < 1024) {
                                        handleQuickLinkClick()
                                    }
                                }} className="title" style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 14, fontFamily: "Roboto, sans-serif" }}>
                                    {"QUICK LINKS"}
                                </div>
                                {
                                    windowDimensions.width < 1024 &&
                                    <i onClick={handleQuickLinkClick} className={!showQuickLink ? "fa fa-chevron-right" : "fa fa-chevron-down"} style={{ color: '#3582B9', marginLeft: 15, paddingBottom: 3, cursor: 'pointer' }} />
                                }
                            </div>
                            {/* <div className="title" style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 14, fontFamily: "Roboto, sans-serif" }}>{t("QUICK LINKS")}</div> */}
                            {showQuickLink && (
                                <div className={`animated-componentfade-in ${isVisible ? 'visible' : ''}`}>
                                    <div onClick={e => {
                                        if (currentPath === '/website/page-login') {
                                            history.push('/website/#beranda')
                                        }
                                        else {
                                            let hero = document.getElementById("beranda");
                                            e.preventDefault();
                                            hero && hero.scrollIntoView();
                                        }

                                    }}
                                        href="#beranda" style={{ marginTop: 5, cursor: 'pointer', color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>{"Home"}</div>
                                    <div onClick={e => {
                                        if (currentPath === '/website/page-login') {
                                            history.push('/website/#modul')
                                        }
                                        else {
                                            let hero = document.getElementById("modul");
                                            e.preventDefault();
                                            hero && window.scrollTo({
                                                top: hero.offsetTop - 100,
                                                behavior: 'smooth'
                                            });
                                        }
                                    }}
                                        href="#modul" style={{ marginTop: 5, cursor: 'pointer', color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>{"Modul"}</div>

                                    <div onClick={e => {
                                        if (currentPath === '/website/page-login') {
                                            history.push('/website/#fitur')
                                        }
                                        else {
                                            let hero = document.getElementById("fitur");
                                            e.preventDefault();
                                            hero && window.scrollTo({
                                                top: hero.offsetTop - 100,
                                                behavior: 'smooth'
                                            });
                                        }
                                    }}
                                        href="#fitur" style={{ marginTop: 5, cursor: 'pointer', color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>{"Produk"}</div>
                                    <a onClick={e => {
                                        if (currentPath === '/website/page-login') {
                                            history.push('/website/#about')
                                        }
                                        else {
                                            let hero = document.getElementById("about");
                                            e.preventDefault();
                                            hero && window.scrollTo({
                                                top: hero.offsetTop - 100,
                                                behavior: 'smooth'
                                            });
                                        }
                                    }}
                                        style={{ color: '#000', textDecoration: 'none' }} href="#about">
                                        <div style={{ marginTop: 5, cursor: 'pointer', color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>{"FAQs"}</div>
                                    </a>
                                    <div onClick={() => history.push('/website/page-login')} style={{ marginTop: 5, cursor: 'pointer', color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>{t("Ask for Demo")}</div>
                                </div>
                            )
                            }
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div onClick={() => {
                                    if (windowDimensions.width < 1024) {
                                        handleProductsClick()
                                    }
                                }} className="title" style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 14, fontFamily: "Roboto, sans-serif" }}>
                                    {"PRODUCTS"}
                                </div>
                                {
                                    windowDimensions.width < 1024 &&
                                    <i onClick={handleProductsClick} className={!showProducts ? "fa fa-chevron-right" : "fa fa-chevron-down"} style={{ color: '#3582B9', marginLeft: 15, paddingBottom: 3, cursor: 'pointer' }} />
                                }
                            </div>
                            {showProducts && (
                                <div className={`animated-componentfade-in ${isVisible ? 'visible' : ''}`}>                                    <div style={{ marginTop: 5, color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>POS</div>
                                    <div style={{ marginTop: 5, color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>Back Office</div>
                                </div>
                            )}
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div onClick={() => {
                                    if (windowDimensions.width < 1024) {
                                        handleContactClick()
                                    }
                                }} className="title" style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 14, fontFamily: "Roboto, sans-serif" }}>
                                    {"CONTACT & INFORMATION"}
                                </div>
                                {
                                    windowDimensions.width < 1024 &&
                                    <i onClick={handleContactClick} className={!showContact ? "fa fa-chevron-right" : "fa fa-chevron-down"} style={{ color: '#3582B9', marginLeft: 15, paddingBottom: 3, cursor: 'pointer' }} />
                                }
                            </div>
                            {
                                showContact &&
                                <div className={`animated-componentfade-in ${isVisible ? 'visible' : ''}`}>
                                    <div style={{ marginTop: 5, display: 'flex' }}>
                                        <IconPhone />
                                        <a onClick={() => { handleWhatsAppButtonClick() }} style={{ color: '#000', textDecoration: 'none', cursor: 'pointer', fontFamily: "Roboto, sans-serif" }} >
                                            <div className="col" style={{ color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>
                                                +62 817392286
                                            </div>
                                        </a>
                                    </div>
                                    <div style={{ marginTop: 5, display: 'flex' }}>
                                        <IconEmail />
                                        <a style={{ color: '#000', textDecoration: 'none', fontFamily: "Roboto, sans-serif" }} href="mailto:Sales@uruz.id?subject=Saya%20Ingin%20Menggunakan%20URUZ">                                    <div className="col" style={{ color: '#ffff', fontSize: 16 }}>
                                            Sales@uruz.id
                                        </div>
                                        </a>
                                    </div>
                                    <div style={{ marginTop: 5, display: 'flex' }}>
                                        <IconMaps />
                                        <div className="col" style={{ color: '#ffff', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>
                                            Ruko Green Mansion Blok C No.17
                                            Jl. Daan Mogot KM 10, Cengkareng, Jakarta - 11710
                                        </div>
                                    </div>
                                </div>
                            }


                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10" xl="3" >

                            <div style={{ marginTop: 10, marginLeft: '-4%' }}>
                                <div className="col" style={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: 16, fontFamily: "Roboto, sans-serif" }}>
                                    <div className="title" style={{ fontFamily: "Roboto, sans-serif" }}> Follow us on</div>

                                </div>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10" xl="3">

                            <div style={{ marginTop: 5, display: 'flex', marginLeft: '-4%' }}>
                                <div className="col" style={{ color: '#ffff' }}>
                                    <a target="_blank" style={{ color: '#000', textDecoration: 'none', }} href="https://bit.ly/FacebookUruz">
                                        <img loading="lazy" src="https://img.icons8.com/fluency/48/000000/facebook-new.png" style={{ width: 30, }} />                                </a>
                                    <a href="https://bit.ly/YoutubeUruz" >
                                        <img loading="lazy" src="https://img.icons8.com/fluency/48/000000/youtube-play.png" style={{ width: 30, marginLeft: '2%' }} />
                                    </a>
                                    <a target="_blank" style={{ color: '#000', textDecoration: 'none', marginLeft: '4%' }} href="https://instagram.com/uruzsolusiteknologi?utm_medium=copy_link">
                                        <IconInstagram style={{ width: 50 }} />
                                    </a>
                                    <a target="_blank" style={{ color: '#000', textDecoration: 'none', marginLeft: '4%' }} href="https://www.linkedin.com/company/pt-uruz-solusi-teknologi">
                                        <IconLinkeDin style={{ width: 50 }} />
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                        </Col>
                        <Col className="ml-auto mr-auto" md="10" xl="3">
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Contact;
