import React from "react";

import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Alert,
    Spinner
  } from "reactstrap";
  import Background from "assets/image/HeaderLogin.png"
  import { useTranslation } from "react-i18next"
  import { url, userServer } from "constant";
import { useEffect } from "react/cjs/react.production.min";

export default function Header() {
    const { t } = useTranslation();
    const [name, setName] = React.useState(false);
    const [tlp, setTlp] = React.useState(false);
    const [address, setAddress] = React.useState(false);
    const [province, setProvince] = React.useState(false);
    const [city, setCity] = React.useState(false);
    const [npwp, setNpwp] = React.useState(false);
    const [wa, setWa] = React.useState(false);
    const [email, setEmail] = React.useState(false);
    const [selected, setLastClicked] = React.useState("DISTRIBUTION")
    const [alertSucces, setAlertSucces] = React.useState(false)
    const [type, setType] = React.useState('')
    const [message, setMessage] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [input, setInput] =React.useState({
      name: '',
      tlp: '',
      email: '',
      address: '',
      province :'',
      city :'',
      npwp :'',
      wa :'',
      keterangan : ''
    })

    
    
    async function saveSendEmail() {
      // if (input['name'] == '') {
      //   setMessage("Nama tidak boleh kosong")
      //   setAlertSucces(true)
      //   setType("danger")
      //   return
      // }
      // if (input['tlp'] == '') {
      //   setMessage("Nomer Handphone tidak boleh kosong")
      //   setType("danger")
      //   setAlertSucces(true)
      //   return
      // }
      // if (input['email'] == '') {
      //   setMessage("Email tidak boleh kosong")
      //   setType("danger")
      //   setAlertSucces(true)
      //   return
      // }
      // else {
        setLoading(true)
        fetch(`${url}send_email_web/`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFkbWluIiwiaWF0IjoxNTY4ODc4OTA3LCJleHAiOjE1NjkwNTUzMDd9.3VpnG1xTsy8KKwfnngBIj4RQ0it3ihElshDxB7H0eWI',
            'Cache-Control': 'no-cache',
            'Accept-Encoding':'gzip;q=1.0, compress;q=0.5',
          },
          body: JSON.stringify({
            "subjectnya": "Harga Uruz",
            "appsnya"   : "uruz-web",
            "namanya"   : input['name'],
            "emailnya"  : input['email'],
            "mobilenya" : input['tlp'],
            "productnya": selected
          })
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false)
          if (res.status == 'OK') {
            setMessage("Terimaksih telah mengajukan demo, tim kami akan segera menghubungi anda.")
            setAlertSucces(true)
            setType("success")
            setInput({
                name: '',
                tlp: '',
                email: '',
                address : '',
                city : '',
                province : '',
                wa : ''
            })
            // setTimeout(() => {
            //   setAlertSucces(false)
            // },10000)
          }
        })
      // }
    }

    async function saveGuest() {
      if (input['name'] == '') {
        setMessage("Nama tidak boleh kosong")
        setAlertSucces(true)
        setType("danger")
        return
      }
      if (input['tlp'] == '') {
        setMessage("Nomor Handphone tidak boleh kosong")
        setType("danger")
        setAlertSucces(true)
        return
      }
      // if (input['email'] == '') {
      //   setMessage("Email tidak boleh kosong")
      //   setType("danger")
      //   setAlertSucces(true)
      //   return
      // }
      // if (input['address'] == '') {
      //   setMessage("Alamat tidak boleh kosong")
      //   setType("danger")
      //   setAlertSucces(true)
      //   return
      // }
      else {
        setLoading(true)
        fetch(`${url}lead/save`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Accept-Encoding':'gzip;q=1.0, compress;q=0.5',
          },
          body: JSON.stringify(
            {
              "from_origin": "*",
              "userServer": userServer,
              "userId": "Herman",
              "mode": "new",
              "detailData": JSON.stringify({
                  "name"    : input['name'],
                  "address" : input['address'] ? input['address'] : "-",
                  "province": input['province'] ? input['province'] :'-',
                  "city"    : input['city'] ? input['city'] : '-',
                  "email"   : input['email'] ? input['email'] : "-",
                  "npwp"    : input['npwp'],
                  "phone1"  : input['tlp'],
                  "wa"      : input['wa'] ? input['wa'] : '0',
                  "keterangan"    : selected,
                  "source"        :"website"
            })
            }
          )
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false)
          if (res.status == 'OK') {
            setMessage("Terimaksih telah mengajukan demo, tim kami akan segera menghubungi anda.")
            setAlertSucces(true)
            setType("success")
            setInput({
                name: '',
                tlp: '',
                email: '',
                address : '',
                city : '',
                province : '',
                wa : ''
            })
            // setTimeout(() => {
            //   setAlertSucces(false)
            // },10000)

            saveSendEmail()

          }
        })
      }
    }   


    return (
        <>
            <div className="section section-tabs" style={{ background: "url(" + Background + ")", marginTop: 80, backgroundSize: 'auto'}}>
              <Container style={{ height: 200}}>
                <Row>
                  <Col className="ml-auto mr-auto" md="10" xl="6">
                  </Col>
                  <Col className="ml-auto mr-auto" md="10" xl="6">
                      <h2 className="title">{t("Request Demo")}
                      </h2>
                  </Col>
                </Row>
              </Container>
            </div>
            
              <div className="section section-contact-us text-center">
                  <Container>
                    <Row>
                      <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                        {/* {
                          alertSucces &&
                        } */}
                        <Alert color={type} toggle={() => setAlertSucces(false)}  fade={false} isOpen={alertSucces}>
                          {message}
                        </Alert>
                        {/* <Alert color="success" toggle={() => setMessage(false)}  fade={false} isOpen={message}>
                          {message}
                        </Alert> */}
                        <InputGroup
                          className={
                            "input-lg" + (name ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={(val) => setInput(value => (
                              {
                                ...value,
                                name: val.target.value
                              }
                            ))}
                            value={input['name']}
                            placeholder={t("input name")}
                            type="text"
                            onFocus={() => setName(true)}
                            onBlur={() => setName(false)}
                          ></Input>
                        </InputGroup>

                        <InputGroup
                          className={
                            "input-lg" + (tlp ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                              value={input['tlp']}
                              onChange={(val) => setInput(value => (
                                {
                                  ...value,
                                  tlp: val.target.value
                                }
                              ))}
                            placeholder="+6212xxxxx"
                            type="text"
                            onFocus={() => setTlp(true)}
                            onBlur={() => setTlp(false)}
                          ></Input>
                        </InputGroup>    

                         {/* <InputGroup
                          className={
                            "input-lg" + (wa ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                              value={input['wa']}
                              onChange={(val) => setInput(value => (
                                {
                                  ...value,
                                  wa: val.target.value
                                }
                              ))}
                            placeholder="wa: +6212xxxxx"
                            type="text"
                            onFocus={() => setWa(true)}
                            onBlur={() => setWa(false)}
                          ></Input>
                        </InputGroup>  */}

                         {/* <InputGroup
                            className={
                              "input-lg" + (npwp ? " input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                value={input['npwp']}
                                onChange={(val) => setInput(value => (
                                  {
                                    ...value,
                                    npwp: val.target.value
                                  }
                                ))}
                              placeholder="npwp : 00-000-000-0-000-000"
                              type="text"
                              onFocus={() => setNpwp(true)}
                              onBlur={() => setNpwp(false)}
                            ></Input>
                          </InputGroup>    

                        {/* <InputGroup
                          className={
                            "input-lg" + (province ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                              value={input['province']}
                              onChange={(val) => setInput(value => (
                                {
                                  ...value,
                                  province: val.target.value
                                }
                              ))}
                            placeholder="Province"
                            type="text"
                            onFocus={() => setProvince(true)}
                            onBlur={() => setProvince(false)}
                          ></Input>
                        </InputGroup> */}

                        <InputGroup
                          className={
                            "input-lg" + (email ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={input['email']}
                            onChange={(val) => setInput(value => (
                              {
                                ...value,
                                email: val.target.value
                              }
                            ))}
                            placeholder="Email"
                            type="text"
                            onFocus={() => setEmail(true)}
                            onBlur={() => setEmail(false)}
                          ></Input>
                        </InputGroup>

                        <InputGroup
                          className={
                            "input-lg" + (city ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                              value={input['city']}
                              onChange={(val) => setInput(value => (
                                {
                                  ...value,
                                  city: val.target.value
                                }
                              ))}
                            placeholder="City"
                            type="text"
                            onFocus={() => setCity(true)}
                            onBlur={() => setCity(false)}
                          ></Input>
                        </InputGroup>

                        {/* <InputGroup
                          className={
                            "input-lg" + (address ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={input['address']}
                            onChange={(val) => setInput(value => (
                              {
                                ...value,
                                address: val.target.value
                              }
                            ))}
                            placeholder="Address"
                            type="text"
                            onFocus={() => setAddress(true)}
                            onBlur={() => setAddress(false)}
                          ></Input>
                        </InputGroup> */}

                          <div style={{ border: '1px solid #DADDDF', borderRadius: 10}}>
                            <UncontrolledDropdown setActiveFromChild >
                              <DropdownToggle style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginLeft: 20,
                                  fontSize: 13
                              }} tag="a" className="nav-link" caret >
                                {selected}
                              </DropdownToggle>
                              <DropdownMenu style={{ width: '100%'}}>
                                <DropdownItem onClick={() => setLastClicked('DISTRIBUTION')}>DISTRIBUTION</DropdownItem>
                                <DropdownItem onClick={() => setLastClicked('FNB')}>FNB</DropdownItem>
                                {/* <DropdownItem onClick={() => setLastClicked('POS')}>POS</DropdownItem>
                                <DropdownItem onClick={() => setLastClicked('Back Office')}>Back Office</DropdownItem>
                                <DropdownItem onClick={() => setLastClicked('Delivery Order')}>Delivery Order</DropdownItem>
                                <DropdownItem onClick={() => setLastClicked('ERP')}>ERP</DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>


                        <div className="send-button" style={{ marginTop: 50}}>
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            // href="#pablo"
                            onClick={saveGuest}
                            size="lg"
                          >
                            {
                              loading ?
                              <Spinner color="info" />
                              :
                              <span>{t("KIRIM")}</span>
                            }
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
        </>
    )
}