import React from "react";
import UruzMakot from "assets/image/Uruz Maskot.png"
import { Row } from "reactstrap";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function Footer(params) {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());

    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <div className="col">
        <div style={{ borderBottom: '0.5px solid #ffff' }} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(4, 41, 94, 1)' }}>
        {windowDimensions.width > 500 ?
          <div style={{ textAlign: 'center', color: '#ffff', fontSize: 20, marginBottom: '1%', marginTop: '1%', fontSize: 18 ,fontFamily:"Roboto, sans-serif"}}>© 2023 PT. Uruz Solusi Teknologi.  All rights reserved.</div>
          :
          <div>
            <div style={{ textAlign: 'center', color: '#ffff', fontSize: 20, marginBottom: '1%', marginTop: '1%', fontSize: 18 ,fontFamily:"Roboto, sans-serif"}}>© 2023 PT. Uruz Solusi Teknologi.</div>
            <div style={{ textAlign: 'center', color: '#ffff', fontSize: 20, marginBottom: '1%', marginTop: '1%', fontSize: 18 ,fontFamily:"Roboto, sans-serif"}}>   All rights reserved.</div>
          </div>
        }
      </div>
    </>
  )
}

export default Footer