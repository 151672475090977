import React from "react";

function IconPoint(params) {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 7C14.9391 7 13.9217 7.44777 13.1716 8.2448C12.4214 9.04183 12 10.1228 12 11.25V36.75C12 37.8772 12.4214 38.9582 13.1716 39.7552C13.9217 40.5522 14.9391 41 16 41H32C33.0609 41 34.0783 40.5522 34.8284 39.7552C35.5786 38.9582 36 37.8772 36 36.75V11.25C36 10.1228 35.5786 9.04183 34.8284 8.2448C34.0783 7.44777 33.0609 7 32 7H16ZM24 36.75C24.5304 36.75 25.0391 36.5261 25.4142 36.1276C25.7893 35.7291 26 35.1886 26 34.625C26 34.0614 25.7893 33.5209 25.4142 33.1224C25.0391 32.7239 24.5304 32.5 24 32.5C23.4696 32.5 22.9609 32.7239 22.5858 33.1224C22.2107 33.5209 22 34.0614 22 34.625C22 35.1886 22.2107 35.7291 22.5858 36.1276C22.9609 36.5261 23.4696 36.75 24 36.75Z" fill="#3582B9"/>
</svg>

    )
}

export default IconPoint