import React, { useState } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,

} from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import Icons from "assets/Icons/Icons";
import IconsPurchasing from "assets/Icons/IconPurchasing";
import IconsSales from "assets/Icons/IconSales";
import IconsSuplay from "assets/Icons/IconSuplay";
import IconsManagement from "assets/Icons/IconManagement";
import IconPoint from "assets/Icons/IconPoint";
import dist from "assets/image/bg-img/dist.png"
import ku1 from "assets/image/bg-img/ku1.png"
import ku2 from "assets/image/bg-img/ku2.png"
import ku3 from "assets/image/bg-img/ku3.png"
import ku4 from "assets/image/bg-img/ku4.png"
import ku5 from "assets/image/bg-img/ku5.png"
import ku6 from "assets/image/bg-img/ku6.png"
import ku7 from "assets/image/bg-img/ku7.png"
import ku8 from "assets/image/bg-img/ku8.png"
import ku9 from "assets/image/bg-img/ku9.png"
import ku10 from "assets/image/bg-img/ku10.png"
import imagefnb from "assets/image/bg-img/permasalahan.webp"
import imagedist from "assets/image/bg-img/permasalahandist.webp"
import '../../components/Headers/Header.css';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function Tabs() {
  const { t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [fnb, setfnb] = useState('#3582B9');
  const [dist, setdist] = useState('rgba(53, 130, 185, 0.16)');
  const [pilih, setpilih] = useState(imagefnb)
  const [textcolorfnb, settextcolorfnb] = useState("#ffff");
  const [textcolordist, settextcolordist] = useState("#3582B9");
  const funcfnb = () => {
    if (fnb === "rgba(53, 130, 185, 0.16)") {
      setdist(state => {
        return state === "#3582B9"
          ? "rgba(53, 130, 185, 0.16)"
          : "#3582B9";
      });
      settextcolorfnb("#ffff")
      settextcolordist("#3582B9")

      // setcolorfnb(state => {
      //   return state === "black"
      //     ? "#ffff"
      //     : "black";
      // });
      setfnb(state => {
        return state === "#3582B9"
          ? "rgba(53, 130, 185, 0.16)"
          : "#3582B9";
      });
      setpilih(imagefnb);
      resetAnimation();
      setResetKey((prevKey) => prevKey + 1);

      // setDatamodul(data);
      // setlimitbawah(0)
      // setlimitatas(3)


    }
    else {
      settextcolorfnb("#ffff")

    };
  }

  const funcdist = () => {
    if (dist === "rgba(53, 130, 185, 0.16)") {

      setdist(state => {
        return state === "#3582B9"
          ? "rgba(53, 130, 185, 0.16)"
          : "#3582B9";
      });
      settextcolordist("#ffff")
      settextcolorfnb("#3582B9")

      setfnb(state => {
        return state === "#3582B9"
          ? "rgba(53, 130, 185, 0.16)"
          : "#3582B9";
      });
      setpilih(imagedist);
      resetAnimation();
      setResetKey((prevKey) => prevKey + 1);
      // setDatamodul(datadist);
      // setlimitbawah(0)
      // setlimitatas(3)
    }
    else {
      settextcolordist("#ffff")

    }

  };
  const [isVisible, setIsVisible] = React.useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  React.useEffect(() => {
    // Trigger the animation after the component mounts
    setIsVisible(true);
  }, []);
  const resetAnimation = () => {
    setAnimationKey((prevKey) => prevKey + 1);
  };

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isVisible2, setIsVisible2] = useState(false);
  const [resetKey, setResetKey] = React.useState(0);
  const [resetKey1, setResetKey1] = React.useState(0);

  const [resetKey2, setResetKey2] = React.useState(0);
  const [resetKey3, setResetKey3] = React.useState(0);

  const [playAnimation, setPlayAnimation] = useState(false);
  const [playAnimation2, setPlayAnimation2] = useState(false);

  console.log(playAnimation, isVisible2)

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 600;
    console.log(scrollTop)

    if (scrollTop > threshold && !playAnimation) {
      setPlayAnimation(true);
    }
    if (scrollTop < 400) {
      setResetKey1((prevKey) => prevKey + 1);
    }
    if (scrollTop < 600) {
      setResetKey((prevKey) => prevKey + 1);
    }
    if (scrollTop > 1100 && scrollTop < 1300) {
      setResetKey2((prevKey) => prevKey + 1);
      setResetKey3((prevKey) => prevKey + 1);

    }
    setIsVisible2(scrollTop > threshold);
  };


  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className="section section-tabs" style={{}}>
        <Container>
          <h3 style={{ textAlign: 'center', color: 'rgba(53, 130, 185, 1)', fontWeight: 800, fontFamily: "Roboto, sans-serif" }}>PERMASALAHAN</h3>
          <h2 style={{ textAlign: 'center', color: 'rgba(46, 45, 86, 1)', fontFamily: "Roboto, sans-serif" }}>Hal yang sering terjadi di dunia Food & Beverages dan Distribution</h2>
        </Container>
        <div style={{ marginTop: 50 }}>
          <Container style={{ marginBottom: '10%' }}>
            <Row>
              <Col key={playAnimation} className={`ml-auto mr-auto animated-componentslidein ${isVisible2 ? 'visible' : ''}`} md="10" xl="6" style={{ marginTop: '5%' }}>
                <div onClick={() => funcfnb()} className={`p-${windowDimensions.width > 1023 ? 4 : 3} `} style={{ height: windowDimensions.width > 800 ? '35%' : '50%', borderRadius: "8px", boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', marginBottom: '5%', backgroundColor: fnb, cursor: 'pointer' }}>
                  <h5 className={` font-weight-bold mb-2`} style={{ color: textcolorfnb, fontFamily: "Roboto, sans-serif" }}>{"Food & Beverages"}</h5>
                  <div className={`font-size-12`} style={{ color: textcolorfnb, fontFamily: "Roboto, sans-serif" }}>{("Berikut beberapa contoh permasalahan yang ada di bisnis retail:")}</div>
                </div>
                <div onClick={() => funcdist()} className={`p-${windowDimensions.width > 1023 ? 4 : 3} pt-10`} style={{ height: windowDimensions.width > 800 ? '35%' : '50%', borderRadius: "8px", boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', backgroundColor: dist, cursor: 'pointer' }}>
                  <h5 className={`font-weight-bold mb-2`} style={{ color: textcolordist, fontFamily: "Roboto, sans-serif" }}>{"Distribution"}</h5>
                  <div className={`font-size-12`} style={{ color: textcolordist, fontFamily: "Roboto, sans-serif" }}>{("Beberapa kendala yang terjadi pada dunia usaha distribusi:")}</div>
                </div>
              </Col>

              <Col key={resetKey} className={`ml-auto mr-auto animated-componentfade-in ${isVisible2 ? 'visible' : ''}`} md="10" xl="6" >
                {isVisible2 && (
                  <img
                    className="animated-componentfade-in visible"
                    loading="lazy"
                    style={{ width: '90%', marginTop: windowDimensions.width > 800 ? 0 : '10%' }}
                    src={pilih}
                  />
                )}              
                </Col>
            </Row>
          </Container>
          <div style={{ backgroundColor: 'rgba(53, 130, 185, 0.06)' }}>

            <Container>
              <h2 className="title" style={{ textAlign: 'center', paddingTop: 50, paddingBottom: 20, fontFamily: "Roboto, sans-serif" }}>{"KENAPA URUZ"}</h2>
              <Row>
                <Col className="ml-auto mr-auto" md="10" xl="6">
                  <Row  key={resetKey2} className={` ml-auto mr-auto animated-componentfade-in ${isVisible ? 'visible' : ''}`}>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku1} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Berkomitmen untuk Kualitas"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku2} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Scalable"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku3} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Cepat di implementasikan"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku8} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Tim Support"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku10} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"ERP Sistem dalam satu platform"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku4} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Mudah digunakan"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku5} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Team yang Berpengalaman"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku8} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Dapat di Custom"}</strong></div>
                      </div>
                    </Col>

                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku7} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Bahasa Indonesia"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                      <div style={{ textAlign: windowDimensions.width > 800 ? 'center' : 'center' }}>
                        <img loading="lazy" style={{ height: 50, width: 50, }} src={ku9} ></img>
                      </div>
                      <div style={{ textAlign: 'center', paddingTop: 10 }}>
                        <div style={{ color: "#2E2D56", textAlign: 'center', fontSize: windowDimensions.width > 800 ? 14 : 11, fontFamily: "Roboto, sans-serif" }}><strong>{"Pelatihan"}</strong></div>
                      </div>
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                    </Col>
                    <Col className="ml-auto mr-auto" xs="3" style={{ paddingBottom: '5%' }} >
                    </Col>
                  </Row>
                </Col>
                <Col key={resetKey3} className={` ml-auto mr-auto animated-componentslideout ${isVisible ? 'visible' : ''}`} md="10" xl="5" >
                  {/* <a href="examples/profile-page.html" target="_blank"> */}
                  <img
                    loading="lazy"
                    alt="..."
                    className="img-raised"
                    src={require("../../assets/image/bg-img/KenapaUruz.webp").default}
                    style={{ marginTop: windowDimensions.width > 800 ? 40 : 0 }}
                  ></img>
                  {/* </a> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>


      </div>

    </>
  );
}

export default Tabs;
