import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Icons from "assets/Icons/Icons";
import IconChart from "assets/Icons/IconChart";
import IconTas from "assets/Icons/IconTas";
// core components
import cl1 from 'assets/image/client/cl1.webp'
import cl2 from 'assets/image/client/cl2.webp'
import cl3 from 'assets/image/client/cl3.webp'
import cl4 from 'assets/image/client/cl4.webp'
import cl5 from 'assets/image/client/cl5.webp'
import cl6 from 'assets/image/client/cl6.webp'
import cl7 from 'assets/image/client/cl7.webp'
import cl8 from 'assets/image/client/cl8.webp'
import cl9 from 'assets/image/client/cl9.webp'
import cl10 from 'assets/image/client/cl10.webp'
import cl11 from 'assets/image/client/cl11.webp'
import cl12 from 'assets/image/client/cl12.webp'
import cl13 from 'assets/image/client/cl13.webp'
import cl14 from 'assets/image/client/cl14.webp'
import cl15 from 'assets/image/client/cl15.webp'
import cl16 from 'assets/image/client/cl16.webp'
import cl17 from 'assets/image/client/cl17.webp'
import cl18 from 'assets/image/client/cl18.webp'
import cl19 from 'assets/image/client/cl19.webp'
import cl20 from 'assets/image/client/cl20.webp'
import cl21 from 'assets/image/client/cl21.webp'
import cl22 from 'assets/image/client/cl22.webp'
import cl23 from 'assets/image/client/cl23.webp'
import taso from 'assets/image/client/taso.webp'
import kks from 'assets/image/client/kks.webp'
import yank from 'assets/image/client/yank.webp'
import ten from 'assets/image/client/ten.webp'
import d2c from 'assets/image/client/d2c.webp'
import shakti from 'assets/image/client/shakti.webp'

import Carousel from 'better-react-carousel'


import { useTranslation } from "react-i18next";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function Client() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  const data = [
    {
      "id": "1",
      "image": cl1
    },
    {
      "id": "2",
      "image": cl2
    },
    {
      "id": "3",
      "image": cl3
    },
    {
      "id": "4",
      "image": cl4
    },
    {
      "id": "5",
      "image": cl5
    },
    {
      "id": "6",
      "image": cl6
    },
    {
      "id": "7",
      "image": cl7
    },
    {
      "id": "8",
      "image": cl8
    },
    {
      "id": "9",
      "image": cl9
    },
    {
      "id": "10",
      "image": cl10
    },
    {
      "id": "11",
      "image": cl11
    },
    {
      "id": "12",
      "image": cl12
    },
    {
      "id": "13",
      "image": cl13
    },
    {
      "id": "14",
      "image": cl14
    },
    {
      "id": "15",
      "image": cl15
    },
    {
      "id": "16",
      "image": cl16
    },
    {
      "id": "17",
      "image": cl17
    },
    {
      "id": "18",
      "image": cl18
    },
    {
      "id": "19",
      "image": cl19
    },
    {
      "id": "20",
      "image": cl20
    },
    {
      "id": "21",
      "image": cl21
    },
    {
      "id": "22",
      "image": cl22
    },
    {
      "id": "23",
      "image": cl23
    },
    {
      "id": "24",
      "image": taso
    },
    {
      "id": "25",
      "image": kks
    },
    {
      "id": "26",
      "image": yank
    },
    {
      "id": "27",
      "image": d2c
    },
    {
      "id": "24",
      "image": ten
    },
    {
      "id": "24",
      "image": shakti
    },
  ]
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const listItems = data.map(
    (element) => {
      return (
        <img loading="lazy" style={{ width: '10%', height: '10%', marginLeft: '5%', marginBottom: '5%', justifyContent: 'center', alignItems: 'center' }} src={element.image} />
      )
    }
  )
  const getColCount = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1200) {
      return 4;
    } else if (screenWidth >= 992) {
      return 3;
    } else if (screenWidth >= 768) {
      return 2;
    } else {
      return 1;
    }
  };
  const colCount = getColCount();
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const groupedData = chunkArray(data, colCount * 2); // Each group represents 2 rows

  const responsiveSettings = [
    { breakpoint: 800, slidesToShow: 2 },
    { breakpoint: 1200, slidesToShow: 3 },
    { breakpoint: 1600, slidesToShow: 4 },
    { breakpoint: 2000, slidesToShow: 6 },
  ];
  return (
    <>
      <div className="section section-tabs" style={{ backgroundColor: 'rgba(53, 130, 185, 0.06)' }}>
        <Container style={{}}>
        <h3 style={{ textAlign: 'center', color: 'rgba(53, 130, 185, 1)', paddingBottom: 30, fontWeight: 800 }}>KLIEN KAMI</h3>

          <Carousel cols={5} rows={2} gap={10} loop autoplay={3000} mobileBreakpoint={800}>
            {data.map(item => (
              <Carousel.Item key={item.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                  <img
                    className="d-block mx-auto"
                    src={item.image}
                    alt={`Slide ${item.id}`}
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' ,backgroundColor:'transparent'}}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>

          {/* <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Col style={{
              justifyContent: 'center', alignItems: 'center'
            }}>
              {listItems}
            </Col>
          </Row> */}
        </Container>
      </div>

    </>
  );
}

export default Client;
