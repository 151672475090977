import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  NavLink,
  Button,
  Collapse,
  Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap";
import IconProduct from "assets/Icons/IconProduct";
import { Link } from "react-router-dom";
import delivery from "assets/image/bg-img/delivery.png"
import pos from "assets/image/bg-img/pos.png"
import Tableqr from "assets/image/bg-img/tableqr.png"
import erp from "assets/image/bg-img/uruzerp.webp"
import moduldist from "assets/image/bg-img/moduldist.webp"
import salesarmy from "assets/image/bg-img/salesarmy.png"
import crm from "assets/image/bg-img/crm.png"
import clientpage from "assets/image/bg-img/clientpage.png"
import project from "assets/image/bg-img/project.png"
import inventory from "assets/image/bg-img/inventory.png"
import purchase from "assets/image/bg-img/purchase.png"
import sales from "assets/image/bg-img/sales.png"
import accounting from "assets/image/bg-img/accounting.png"
// import commingsoon from "assets/image/bg-img/comingsoon.png"
import commingsoon from "assets/image/bg-img/videocomingsoon.webp"
import '../../components/Headers/Header.css';
import './modul.css'
// core components

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function Modul(props) {
  const { title, collapse } = props;

  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [fnb, setfnb] = useState('#3582B9');
  const [dist, setdist] = useState('#ffff');
  const [colorfnb, setcolorfnb] = useState("ffff")
  const [colordist, setcolordist] = useState("ffff")
  const [pilihfnb, setpilihfnb] = useState(erp)
  const [isCollapse5, setIsCollapse5] = useState(collapse);
  const [tampung, setTampung] = React.useState('')
  const [tampungkondisi, setTampungkondisi] = React.useState(false)
  const [tampungIndex, setTampungIndex] = React.useState()
  const [limitbawah, setlimitbawah] = React.useState(0)
  const [limitatas, setlimitatas] = React.useState(3)
  const [icon1, setIcon1] = useState("fa fa-chevron-right");
  const [datamodul, setDatamodul] = useState([{}]);
  const [textcolorfnb, settextcolorfnb] = useState("#ffff");
  const [textcolordist, settextcolordist] = useState("black");

  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggle = () => setModal(!modal);
  const funcfnb = () => {
    if (fnb === "#ffff") {
      setdist(state => {
        return state === "#3582B9"
          ? "#ffff"
          : "#3582B9";
      });
      settextcolorfnb("#ffff")
      settextcolordist("black")

      // setcolorfnb(state => {
      //   return state === "black"
      //     ? "#ffff"
      //     : "black";
      // });
      setfnb(state => {
        return state === "#3582B9"
          ? "#ffff"
          : "#3582B9";
      });
      setpilihfnb(erp);
      setDatamodul(data);
      setlimitbawah(0)
      setlimitatas(3)
      resetAnimation()

    }
    else {
      settextcolorfnb("#ffff")

    };
  }


  const funcdist = () => {
    if (dist === "#ffff") {

      setdist(state => {
        return state === "#3582B9"
          ? "#ffff"
          : "#3582B9";
      });
      settextcolordist("#ffff")
      settextcolorfnb("black")

      setfnb(state => {
        return state === "#3582B9"
          ? "#ffff"
          : "#3582B9";
      });
      setpilihfnb(moduldist);
      setDatamodul(datadist);
      setlimitbawah(0)
      setlimitatas(3)
      resetAnimation()
    }
    else {
      settextcolordist("#ffff")

    }

  };

  const data = [
    {
      "id": 1,
      "image": pos,
      "nama": "POS",
      "video": "https://drive.google.com/file/d/1TvGqj0VAxcwMgHTFXSvSulqzGJgTyTLX/preview",
      "description": "Berfungsi untuk mencatat semua transaksi penjualan beserta laporannya...",
      "fulldescription": "Berfungsi untuk mencatat semua transaksi penjualan beserta laporannya dan dapat menghitung jumlah stock secara otomatis."
    },
    {
      "id": 2,
      "image": Tableqr,
      "nama": "Table QR",
      "video": "https://drive.google.com/file/d/1wPSCw2MsKk4PGfhykTqn0WTbywdnRlxh/preview",
      "description": "Bermanfaat untuk mengurangi antrian customer hanya dengan scan barcode QR...",
      "fulldescription": "Bermanfaat untuk mengurangi antrian customer hanya dengan scan barcode QR melalui device customer untuk memesan hingga membayar orderan langsung secara online dan dapat digunakan sebagai alat bantu untuk memanggil waiters."
    },
    {
      "id": 3,
      "image": delivery,
      "nama": "Delivery",
      "video": "Comming Soon",
      "description": "Dapat digunakan untuk meningkatkan penjualan melalui Web Based URUZ...",
      "fulldescription": "Dapat digunakan untuk meningkatkan penjualan melalui Web Based URUZ (tidak diperlukan mark-up harga) dengan opsi pengiriman dari merchant atau dengan partner GO-ride."
    },
    {
      "id": 4,
      "image": inventory,
      "nama": "Inventory",
      "video": "https://drive.google.com/file/d/1rydnR9j8FaKtaIhUr-sfmGMOBbjrJ1S1/preview",
      "description": "Pengelolaan stok barang dagang pada perusahaan sangat memegang peran...",
      "fulldescription": "Pengelolaan stok barang dagang pada perusahaan sangat memegang peran penting dalam menjaga level stok barang maupun masa waktu barang agar tidak terjadi kesalahan dalam pengeluaran barang."
    },
    {
      "id": 5,
      "image": purchase,
      "nama": "Purchase",
      "video": "https://drive.google.com/file/d/15u-ooE-eXrRqRMporXrMfoCCd4ZqY8Jc/preview",
      "description": "Setiap perusahaan pasti akan memerlukan aktivitas pembelian barang...",
      "fulldescription": "Setiap perusahaan pasti akan memerlukan aktivitas pembelian barang baik untuk aset, dijual kembali, operasional dan kebutuhan pendukung lainnya melalui proses pembelian ke supplier yang dapat tercatat dengan rapi, terukur, efektif, efisien, dan transparan."
    },
    {
      "id": 6,
      "image": sales,
      "nama": "Sales",
      "video": "https://drive.google.com/file/d/1Pp8r7EG8L3RBgL5-TC4Alba6yHatDCG0/preview",
      "description": "Modul ini sangat krusial sebagai ujung tombak perusahaan untuk melakukan...",
      "fulldescription": "Modul ini sangat krusial sebagai ujung tombak perusahaan untuk melakukan transaksi penjualan barang atau jasa ke pelanggan dengan cepat serta dapat memonitor siklus dan tracking history penjualan dengan detail."
    },
    {
      "id": 7,
      "image": accounting,
      "nama": "Accounting",
      "video": "Comming Soon",
      "description": "Modul ini berperan penting dalam pengelolaan keuangan perusahaan seperti...",
      "fulldescription": "Modul ini berperan penting dalam pengelolaan keuangan perusahaan seperti arus kas, pembayaran, utang-piutang, hingga pelaporan rugi laba secara otomatis."
    },
  ]
  const datadist = [
    {
      "id": 1,
      "image": salesarmy,
      "nama": "Sales Army",
      "video": "Comming Soon",
      "description": "Digunakan untuk menginput penjualan atau pengecekan stock....",
      "fulldescription": "Digunakan untuk menginput penjualan atau pengecekan stock oleh tim sales dari manapun secara online."
    },
    {
      "id": 2,
      "image": clientpage,
      "nama": "Client Page",
      "video": "Comming Soon",
      "description": "Digunakan sebagai page untuk customer melihat, membeli...",
      "fulldescription": "Digunakan sebagai page untuk customer melihat, membeli, atau mengecek status orderan mereka secara online."
    },
    {
      "id": 3,
      "image": crm,
      "nama": "CRM",
      "video": "Comming Soon",
      "description": "Digunakan untuk menginput  data calon pelanggan beserta...",
      "fulldescription": "Digunakan untuk menginput  data calon pelanggan beserta progress status follow up."
    },
    {
      "id": 4,
      "image": project,
      "nama": "Project",
      "video": "Comming Soon",
      "description": "Berguna untuk menginput data dari projek beserta tim...",
      "fulldescription": "Berguna untuk menginput data dari projek beserta tim yang terlibat berikut dengan laporan Profit & Loss per projek."
    },
    {
      "id": 5,
      "image": inventory,
      "nama": "Inventory",
      "video": "https://drive.google.com/file/d/1rydnR9j8FaKtaIhUr-sfmGMOBbjrJ1S1/preview",
      "description": "Pengelolaan stok barang dagang pada perusahaan sangat memegang peran...",
      "fulldescription": "Pengelolaan stok barang dagang pada perusahaan sangat memegang peran penting dalam menjaga level stok barang maupun masa waktu barang agar tidak terjadi kesalahan dalam pengeluaran barang."
    },
    {
      "id": 6,
      "image": purchase,
      "nama": "Purchase",
      "video": "https://drive.google.com/file/d/15u-ooE-eXrRqRMporXrMfoCCd4ZqY8Jc/preview",
      "description": "Setiap perusahaan pasti akan memerlukan aktivitas pembelian barang...",
      "fulldescription": "Setiap perusahaan pasti akan memerlukan aktivitas pembelian barang baik untuk aset, dijual kembali, operasional dan kebutuhan pendukung lainnya melalui proses pembelian ke supplier yang dapat tercatat dengan rapi, terukur, efektif, efisien, dan transparan."
    },
    {
      "id": 7,
      "image": sales,
      "nama": "Sales",
      "video": "https://drive.google.com/file/d/1Pp8r7EG8L3RBgL5-TC4Alba6yHatDCG0/preview",
      "description": "Modul ini sangat krusial sebagai ujung tombak perusahaan untuk melakukan...",
      "fulldescription": "Modul ini sangat krusial sebagai ujung tombak perusahaan untuk melakukan transaksi penjualan barang atau jasa ke pelanggan dengan cepat serta dapat memonitor siklus dan tracking history penjualan dengan detail."
    },
    {
      "id": 8,
      "image": accounting,
      "nama": "Accounting",
      "video": "Comming Soon",
      "description": "Modul ini berperan penting dalam pengelolaan keuangan perusahaan seperti...",
      "fulldescription": "Modul ini berperan penting dalam pengelolaan keuangan perusahaan seperti arus kas, pembayaran, utang-piutang, hingga pelaporan rugi laba secara otomatis."
    },

  ]
  const funcdata = (element) => {
    setTampung(element.fulldescription)
    setTampungkondisi(!tampungkondisi)
    setTampungIndex(element.id)
    setModal(!modal)
    resetAnimation();
  };
  const [isVisible, setIsVisible] = React.useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  React.useEffect(() => {
    // Trigger the animation after the component mounts
    setIsVisible(true);
  }, []);
  const resetAnimation = () => {
    setAnimationKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    // toggleFaq1();
  }, [collapse]);
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    setDatamodul(data)
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  const listItems = datamodul.map(
    (element) => {
      if (element.id <= limitatas && element.id > limitbawah) {
        return (

          <Col className={`ml-auto mr-auto`} md="10" xl="3" style={{
            borderRadius: "8px", boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', marginBottom: windowDimensions.width > 800 ? '5%' : 0, height: 250
          }}>
            <div className={`animated-componentfade-in ${isVisible ? 'visible' : ''}`}>
              <div style={{ marginBottom: 20, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <img loading="lazy" style={{ width: '15%', height: '15%', marginTop: '5%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} src={element.image} />
              </div>
              <h5 style={{ color: "#07244E", fontFamily: "Roboto, sans-serif", justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}><strong>{(element.nama)}</strong></h5>

              {
                tampungkondisi == false ?
                  <div style={{ color: '#9c9c9c', fontSize: 12 }}>{element.description}
                    <div style={{ cursor: 'pointer', color: 'rgba(53, 130, 185, 1)', fontFamily: "Roboto, sans-serif" }} onClick={() => funcdata(element)}>{("Baca Lebih Lanjut")}
                    </div>
                  </div>
                  :
                  <div style={{ color: '#9c9c9c', fontSize: 12 }}>{element.description}
                    <div style={{ cursor: 'pointer', color: 'rgba(53, 130, 185, 1)', fontFamily: "Roboto, sans-serif" }} onClick={() => funcdata(element)}>{("Baca Lebih Lanjut")}
                    </div>
                  </div>
              }
            </div>
            {
              tampungIndex === element.id ? (
                <Modal
                  isOpen={modal}
                  toggle={funcdata}
                  modalTransition={{ timeout: 500 }}
                  className="custom-modal"
                >
                  <div style={{
                    borderRadius: '100px',
                  }}>

                    <ModalBody style={{ margin: 20, }}>
                      <Row style={{}}>
                        <img loading="lazy" style={{ width: '15%', height: '15%' }} src={element.image} />
                        <h5 style={{ color: "#07244E", paddingTop: '5%', paddingLeft: '2%', fontFamily: "Roboto, sans-serif" }}>
                          <strong>{element.nama}</strong>
                        </h5>
                        <div style={{ color: 'black', fontSize: 14, fontFamily: "Roboto, sans-serif" }}>{element.fulldescription}</div>
                        {
                          element.video !== 'Comming Soon' ?
                            <iframe
                              title="Google Drive Video"
                              width="600"
                              height="400"
                              src={element.video}
                              frameBorder="0"
                              allow="autoplay; fullscreen"
                              allowFullScreen
                            ></iframe>
                            :
                            <img loading="lazy" style={{ width: '100%', height: '100%', marginTop: '5%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} src={commingsoon} />

                        }

                      </Row>
                    </ModalBody>
                  </div>

                </Modal>
              ) : null
            }

          </Col>

        )
      }
    }
  )


  return (
    <>
      <Container >
        <div key={animationKey} id="modul"  >
          <Container>
            <h3 style={{ textAlign: 'center', color: 'rgba(53, 130, 185, 1)', fontWeight: 800, fontFamily: "Roboto, sans-serif" }}>MODUL</h3>
            <h2 style={{ textAlign: 'center', color: 'rgba(46, 45, 86, 1)', fontFamily: "Roboto, sans-serif" }}>Modul-modul yang sudah saling terintegrasi dalam menunjang dalam pertumbuhan bisnis</h2>
          </Container>

          <Container>
            <Row className="justify-content-md-center" style={{ paddingBottom: 30 }}>
              <Col md={6} lg={4}>
                <Button
                  onClick={() => funcfnb()}
                  style={{ textAlign: 'center', border: '1px solid #3582B9', backgroundColor: fnb, borderRadius: '16px', width: '100%', height: 70, justifyContent: 'center', alignItems: 'center' }}
                >
                  <p style={{ color: textcolorfnb, fontWeight: 'bold', textAlign: 'center', marginTop: 15, fontFamily: "Roboto, sans-serif" }}>{"Food & Beverages"}</p>
                </Button>
              </Col>

              <Col md={6} lg={4} className="mt-3 mt-md-0">
                <Button
                  onClick={() => funcdist()}
                  style={{ textAlign: 'center', border: '1px solid #3582B9', backgroundColor: dist, borderRadius: '16px', width: '100%', height: 70 }}
                >
                  <p style={{ color: textcolordist, fontWeight: 'bold', textAlign: 'center', marginTop: 15, fontFamily: "Roboto, sans-serif" }}>{"Distribution, Retail, & Service"}</p>
                </Button>
              </Col>
            </Row>
          </Container>

          <div style={{ width: '100%', height: '50%', justifyContent: 'center', alignItems: 'center', borderRadius: 20, boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', }}>
            <Container>
              <Row>
                <Row className="ml-auto mr-auto" md="10" xl="6" >
                  <div style={{ borderWidth: 0.5, borderColor: 'black', backgroundColor: '#ffff', borderRadius: 30, margin: 10 }}>
                    <img
                      alt="..."
                      src={pilihfnb}
                      className={`animated-componentfade-in ${isVisible ? 'visible' : ''}`}
                    />
                  </div>
                </Row>
              </Row>
            </Container>
            <Container style={{}}>
              <Row style={{}}>
                <button
                  type="button"
                  onClick={() => { if (limitbawah >= 1) { setlimitbawah(limitbawah - 1); { setlimitatas(limitatas - 1) } } }}
                  style={{
                    borderRadius: '200%', width: windowDimensions.width > 800 ? 60 : 40, height: windowDimensions.width > 800 ? 60 : 40, marginTop: windowDimensions.width > 1024 ? '10%' : 25, marginBottom: windowDimensions.width > 500 ? 0 : '3%',
                    marginLeft: windowDimensions.width > 1024 ? 50 : "43%", border: '1px solid #3582B9', backgroundColor: '#ffff'
                  }}>
                  <i className={windowDimensions.width > 1024 ? "fa fa-chevron-left" : "fa fa-chevron-up"} style={{ color: '#3582B9' }} /> {title}
                </button>
                {listItems}
                <button
                  type="button"
                  onClick={() => { if (limitatas <= datamodul.length - 1) { setlimitbawah(limitbawah + 1); { setlimitatas(limitatas + 1) } } }}
                  style={{
                    borderRadius: '200%', width: windowDimensions.width > 800 ? 60 : 40, height: windowDimensions.width > 800 ? 60 : 40, marginTop: windowDimensions.width > 1024 ? '10%' : 25,
                    marginLeft: windowDimensions.width > 1024 ? 0 : "43%", marginRight: windowDimensions.width > 800 ? 20 : 0, border: '1px solid #3582B9', backgroundColor: '#ffff'
                  }}
                >
                  <i className={windowDimensions.width > 1024 ? "fa fa-chevron-right" : "fa fa-chevron-down"} style={{ color: '#3582B9' }} /> {title}
                </button>

              </Row>
            </Container>
          </div>
        </div>
      </Container>


    </>
  );
}

export default Modul;
