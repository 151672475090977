import React from "react";



function IconProduct(params) {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="7" width="34" height="34" rx="5" fill="#07244E"/>
            <path d="M22 20.3C22 20.6866 21.6866 21 21.3 21H20.7C20.3134 21 20 20.6866 20 20.3V14.7C20 14.3134 19.6866 14 19.3 14H18.7C18.3134 14 18 14.3134 18 14.7V20.3C18 20.6866 17.6866 21 17.3 21H16.7C16.3134 21 16 20.6866 16 20.3V14.7C16 14.3134 15.6866 14 15.3 14H14.7C14.3134 14 14 14.3134 14 14.7V21C14 22.8946 15.3258 24.4697 17.0994 24.876C17.4598 24.9586 17.75 25.2581 17.75 25.6278V33.3C17.75 33.6866 18.0634 34 18.45 34H19.55C19.9366 34 20.25 33.6866 20.25 33.3V25.6278C20.25 25.2581 20.5402 24.9586 20.9006 24.876C22.6742 24.4697 24 22.8946 24 21V14.7C24 14.3134 23.6866 14 23.3 14H22.7C22.3134 14 22 14.3134 22 14.7V20.3ZM27 18V25.3C27 25.6866 27.3134 26 27.7 26H28.8C29.1866 26 29.5 26.3134 29.5 26.7V33.3C29.5 33.6866 29.8134 34 30.2 34H31.3C31.6866 34 32 33.6866 32 33.3V14.7C32 14.3134 31.6849 13.9953 31.3019 14.0478C28.872 14.381 27 16.3911 27 18Z" fill="#ffff"/>
        </svg>

    )
}

export default IconProduct