import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import LogoProduct from "assets/Icons/logoProduct";
import IconHeader from "assets/Icons/IconHeader1";
import Ellipse from "assets/Icons/IconElilipse";
// import Background from "assets/image/Header (1).png"
import Background from 'assets/image/Header123.png'
import Background1 from 'assets/image/Header1234copy.png'
import Background2 from 'assets/image/Header12345copy.png'
import Background3 from 'assets/image/Header123456copy.png'
import Diagram1 from "assets/image/Diagram1.png"
import Diagram2 from "assets/image/Diagram2.png"
import Diagram21 from "assets/image/Diagram21.png"
import Diagram3 from "assets/image/Diagram3.png"
import Diagram4 from "assets/image/Diagram4.png"
import { useHistory } from "react-router-dom"
import { useTranslation, Trans } from "react-i18next";
// core components

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function Header({dataSelected, product}) {
  const { t } = useTranslation();
  let history = useHistory()

  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
    <>  
    {/* <div style={{ backgroundColor: 'pink'}}>
        <IconHeader/>
    </div> */}

{/* style={{ background: "url(" + (product == 'Pos' ? Background : Background1) + ")", marginTop: 98, backgroundSize: windowDimensions.width > 639 ? '70%' : 'auto', backgroundRepeat: 'space', backgroundColor: '#726FFF'}} */}
      <div 
        style={{ marginTop: 90}}
      >
      {/* <div className="section section-tabs" style={{ backgroundColor: data[0]['label'] == 'ERP' ? '#FFE66D' : '#726FFF', backgroundSize: '100%' }}> */}
              {/* <div style={{ backgroundColor: data[0]['label'] !== 'ERP' ? '#FFE66D' : '#726FFF', height: 300, width: 300, borderRadius: '100%', marginLeft: 30, zIndex: -3, marginBottom: -20}}></div> */}
        <Container>
          <Row style={{ backgroundColor: product == 'ERP' ? "#FFB82F" :  '#726FFF'  }}>
            <Col className="ml-auto mr-auto" md="5" xl="6">
              {
                windowDimensions.width > 639 &&
              <>
              {
                   product == 'P O S' && 
                <img src={Background} />
              }
              {
                   product == 'BACK OFFICE' && 
                <img src={Background1} />
              }
              {
                   product == 'DELIVERY ORDER' && 
                <img src={Background2} />
              }
              {
                   product == 'ERP' && 
                <img src={Background3} />
              }
              </>
              }
            </Col>
            <Col className="ml-auto mr-auto" md="5" xl="6" style={{ alignSelf: 'center', height: windowDimensions.width > 639 ? 'auto' : 200, display: 'grid', justifyContent: 'center'}}>
                <LogoProduct />
                {
                  product == 'P O S' && 
                  <div style={{ color: "#fff", fontSize: 25, fontWeight: 'bold'}}>POINT OF SALE</div>
                }
                {
                  product !== 'ERP' &&
                  <div style={{ color: product == 'P O S' ? "#FFE66D" : '#fff', fontSize: 25, fontWeight: 'bold'}}>{product}</div>
                }
                {
                  product == 'ERP' &&
                  <div style={{ color:  '#fff', fontSize: 25, fontWeight: 'bold'}}>ENTERPRISE<br/>RESOURCE<br/>PLANNING</div>
                }
              </Col>
            </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: '#F9F9F9'}}>
        <Container>
          {
            product == 'P O S' && 
            <img src={Diagram1} />
          }
          {
               product == 'BACK OFFICE' && 
              <div>
                {
                  t('beranda') == "Beranda" && 
                    <img src={Diagram2} />
                  }
                {
                  t('beranda') == "Home" && 
                    <img src={Diagram21} />
                }
              </div>
          }
          {
               product == 'DELIVERY ORDER' && 
            <img src={Diagram3} />
          }
          {
               product == 'ERP' && 
            <img src={Diagram4} />
          }
        </Container>
      </div>
      <div className="section section-tabs" style={{ backgroundColor: '#F9F9F9'}}>
        <Container>
          <Row>
            <Col md="10" xl="11">
              {
                 product == 'P O S' && 
                <h3 className="title">{t("label_pos")}</h3>
              }
              {
                 product == 'BACK OFFICE' && 
                <h3 className="title">{t("pos_Uruz_Back_Office")}</h3>
              }
              {
                 product == 'DELIVERY ORDER' && 
                <h3 className="title">{t("pos_Uruz Delivery order")}</h3>
              }
              {
                 product == 'ERP' && 
                <h3 className="title">{t("ERP label")}</h3>
              }
              {
                 product == 'P O S' && 
                <div>
                  <div style={{ color: '#9c9c9c'}}>{t("text_pos")}</div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_promosition")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("pos_promotion_text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_ multiple_payments")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("multiple_payments_text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_Customer_relationship")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("pos_Customer_relationship_text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_stock_notification")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("pos_stock_notification_text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_Order_management")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("pos_Order_management_text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_Sales_monitoring")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("pos_Sales_monitoring_text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("pos_Staff_Management")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("pos_Staff_Management_text")}</div>
                  </div>
                </div>
              }
              {
                product == 'BACK OFFICE' && <div style={{ color: '#9c9c9c'}}>{t("pos_Uruz_Back_Office_text")}</div>
              }
              {
                product == 'DELIVERY ORDER' && <div style={{ color: '#9c9c9c'}}>{t("pos_Uruz Delivery order text")}</div>
              }
              {
                 product == 'ERP' && 
                <div>
                  <div style={{ color: '#9c9c9c'}}>{t("ERP text")}</div>
                  <div style={{ color: '#9c9c9c', marginTop: 10}}>{t("ERP label text")}</div>
                  <div style={{ marginTop: 5 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("ERP Visibility")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("ERP Visibility text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("ERP Real-time")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("ERP Real-time text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("ERP Faster workflow")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("ERP Faster workflow text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("ERP Streamline Omnichannel Sales")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("ERP Streamline Omnichannel Sales text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("ERP Integration")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("ERP Integration text")}</div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ color: '#000', fontWeight: 'bold' }}>{t("ERP Flexibility")}</div>
                    <div style={{  color: '#9c9c9c'}}>{t("ERP Flexibility text")}</div>
                  </div>
               </div>
              }
            </Col>
          </Row>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 50}}>
                <button onClick={() => history.push('/website/page-login')} style={{ padding: '10px 40px', borderRadius: 5, border: '1px solid #5451D6'}}>{t("login ajukan demo")}</button>
            </div>
        </Container>
      </div>
    </>
  );
}

export default Header;
