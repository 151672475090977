import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import cl2 from 'assets/image/client/cl2.webp'
import cl20 from 'assets/image/client/cl20.webp'
import cl7 from 'assets/image/client/cl7.webp'
import taso from 'assets/image/client/taso.webp'
import Carousel from 'better-react-carousel'
import cl18 from 'assets/image/client/cl18.webp'
import ten from 'assets/image/client/ten.webp'
import cl3 from 'assets/image/client/cl3.webp'
import berkobar from 'assets/image/client/berkobar.webp'
import yank from 'assets/image/client/yank.webp'

const testimonialData = [
  {
    author: 'Vittorio Lianto as Owner Vento Toys',
    text: 'Sudah 3 tahun menggunakan URUZ. Selalu update mengenai fitur-fitur baru sesuai dengan kebutuhan user. Tim Uruz selalu siap ketika kami membutuhkan bantuan dalam pengunaan maupun troubleshooting. Dan apabila ada pengerjan fitur tambahan bisa selesai dengan waktu yang cepat dan sudah bisa integrasi dengan software-software lain.',
    image: cl3,
  },
  {
    author: 'Lisa as CS / Marketing PIB',
    text: 'Aplikasinya So Far Very Well karena bisa di akses dimana dan kapan aja, terus untuk aplikasinya mudah dimengerti juga karena setiap menu dan itemnya tersusun secara sistematis.',
    image: cl2,

  },
  {
    author: 'Rosi as Admin Taso',
    text: 'Selama hampir 1 tahun penggunaan aplikasi Uruz sebagai transaksi outlet oleh kasir, juga sebagai absensi, bisa saya sampaikan bahwa aplikasi Uruz ini bagus, ditambah dengan fiturnya yang lengkap tapi ya memang masih perlu disempurnakan terus, supaya semakin optimal.',
    image: taso,
  },
  {
    author: 'Rocky as Manager Accounting Temnus',
    text: 'Dengan memakai sistem uruz, proses penginputan seluruh transaksi jadi lebih mudah. Dari PO, penerimaan barang, stok barang, stock opname, sampai ke penjualan semua bisa di cover dengan baik. Dan setelah nya seluruh rangkaian transaksi bisa menghasilkan laporan yang berbeda-beda sesuai kebutuhan. Kapanpun owner minta laporan, kami bisa sediakan dgn real time.',
    image: ten,
  },
  {
    author: 'Hendra Lim - Waroeng Berkobar',
    text: 'Uruz enak banget di pakai nya, kasir lulusan SMP aja belajar nya gampang, fitur nya juga lengkap dari pembelian, stok, akunting, hrd, semuanya ada. kalau sudah pakai uruz tidak perlu pakai aplikasi lain.',
    image: berkobar,
  },
  {
    author: 'Rocky as Owner Jeerafin',
    text: 'Sistem nya sangat mempermudah dalam pembuatan laporan Keuangan. Karena jeerafin bergerak di bidang jasa pembukuan dan perpajakan yang memiliki klien dari berbagai jenis bidang usaha, seluruh klien bisa tercover dengan ada nya sistem uruz.',
    image: cl18,
  },
  
  {
    author: 'Feby as Manager Gelato',
    text: 'Aplikasinya user friendly, modulnya mudah dipahami dengan fitur-fitur yang sangat support, segala bentuk laporan bisa di export. Dibantu juga oleh tim Uruz yang selalu merespon segala kendala / keluhan yang terjadi.',
    image: cl7,
  },
  {
    author: 'Martin Andrew Lim as Yank Bistro',
    text: 'Aplikasi uruz enak di pakai nya, kitchen display nya juga enak banget, bisa tau kinerja karyawan dalam melayani customer.',
    image: yank,
  },
  {
    author: 'Bu Yoyong as Owner Pillowcake',
    text: 'Aplikasi Uruz memang kurang smooth pada awalnya, namun seiring berjalan waktu dan didukung oleh respon yang sangat bagus dan cepat tanggap oleh tim Uruz, permasalahan yang ada berhasil diperbaiki oleh tim Uruz.',
    image: cl20,
  },

];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Testimonial = ({ testimonial }) => (
  <Col>
    <div style={styles.testimonial}>
      {testimonial.image && <img loading='lazy' src={testimonial.image} alt={testimonial.author} style={styles.image} />}
      <p style={styles.text}>
        <a>"</a>
        {testimonial.text}
        <a>"</a>
      </p>
      <p style={styles.author}>- {testimonial.author}</p>
    </div>
  </Col>
);

const TestimonialSlider = ({ testimonials }) => {
  const chunkSizeTablet = 2; 
  const chunkSizeDesktop = 3; 

  const chunkArray = (array, size) =>
    Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
      array.slice(index * size, index * size + size)
    );
  function checkIsTabletOrAndroid() {
    return window.innerWidth <= 768; 
  }
  const initialIsTabletOrAndroid = checkIsTabletOrAndroid();
  const [isTabletOrAndroid, setIsTabletOrAndroid] = React.useState(initialIsTabletOrAndroid);
  React.useEffect(() => {
    function handleResize() {
      setIsTabletOrAndroid(checkIsTabletOrAndroid());
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const chunkSize = isTabletOrAndroid ? chunkSizeTablet : chunkSizeDesktop;
  const testimonialChunks = chunkArray(testimonials, chunkSize);
  const [columns, setColumns] = React.useState(3);
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Container >
      {
        windowDimensions.width >= 767 ?
        <Carousel cols={windowDimensions.width >= 1024 ? 3 : windowDimensions.width >= 768 && windowDimensions.width <= 1023 ? 2 : 1
      
        } rows={1} gap={10} loop >
        {testimonialData.map(item => (
          <Carousel.Item key={item.id} style={{ textAlign: 'center' }} id="carousel-container">
            <div style={{ width: '100%', height: '100%' }} id="carousel-content">
            <div style={styles.testimonial}>
            {item.image && <img loading='lazy' src={item.image} alt={item.author} style={styles.image} />}
            <p style={styles.text}>
              <a>"</a>
              {item.text}
              <a>"</a>
            </p>
            <p style={styles.author}>- {item.author}</p>
          </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
  
        :
        <div className="testimonials-container">
        {testimonialData.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card" style={{border:'1px solid #ddd',marginTop:'5%',backgroundColor:'white',padding:'5%'}}>
            <img src={testimonial.image} alt={testimonial.author} style={{width:'50%'}}/>
            <p style={styles.text}>{testimonial.text}</p>
            <p style={styles.author} className="author">{testimonial.author}</p>
          </div>
        ))}
      </div>
      }
     
      {/* <Carousel  interval={5000} pause={false}>
        {testimonialChunks.map((chunk, index) => (
          <Carousel.Item key={index} style={{ border: 'none' }}>
            <Row>
              {chunk.map((testimonial) => (
                <Col key={testimonial.id}>
                  <Testimonial testimonial={testimonial} />
                </Col>
              ))}
              {chunk.length === 2 && !isTabletOrAndroid && <Col className="d-none d-md-block"/>}
              {chunk.length === 1 && isTabletOrAndroid && <Col className="d-none d-md-block"/>}

            </Row>
          </Carousel.Item>
        ))}
      </Carousel> */}
    </Container>
  );
};



const App = () => {
  
return(
  <div style={styles.container}>
            <h3 style={{ textAlign: 'center', color: 'rgba(53, 130, 185, 1)', fontWeight: 800,fontFamily:"Roboto, sans-serif" }}>TESTIMONI</h3>
    <TestimonialSlider testimonials={testimonialData} />
  </div>
);
}

const styles = {
  container: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    padding: '20px',
    backgroundColor:'rgba(53, 130, 185, 0.06)',
    marginBottom:'5%'
  },
  header: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  testimonialContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  testimonial: {
    minHeight:'450px',
    maxWidth: '400px',
    margin: '20px',
    padding: '15px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor:'white'

  },
  text: {
    marginBottom: '10px',
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
  },
  author: {
    fontStyle: 'italic',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight:'700'
  },
  image: {
    width: '30%', // Adjust the width as needed
    marginBottom: '10px', // Optional: Adjust margin for spacing
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  customCarouselItem: {
    border: 'none', // Remove the border
  },
};


export default App;
