import React from "react";

import {
  Row,
} from "reactstrap";

import IndexNavbar from "components/Navbars/IndexNavbar.js?vers=1";
import Selections from "./product-sections/Selections";
import Header from "./product-sections/Header?vers=1";
import Contact from "./index-sections/Contact.js?vers=1";
import Footer from "components/Footers/Footer?vers=1";
import About from "./index-sections/About?vers=1"


function PageProduct({location}) {
    const [product, setProduct] = React.useState('P O S')
    const [dataSelected, setDataSelected] = React.useState( [
        {
          label: 'P O S',
          selected: true,
        },
        {
          label: 'Back Office',
          selected: false
        },
        {
          label: 'Delivery Order',
          selected: false
        },
        {
          label: 'ERP',
          selected: false
        },
    ])

    // function onSelected(items) {
    //     const data = dataSelected.filter(x => {
    //       if (x['label'] == items['label']) {
    //        x['selected'] = true
    //       }
    //       else {
    //        x['selected'] = false
    //       }
    //       return x
    //     })
    //     console.log('sukses', data)
    //     setDataSelected(data)
    // }
    
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("index-page");
          document.body.classList.remove("sidebar-collapse");
        };
    });


    function onProduct(params) {
      setProduct(params)
    }
    
    React.useEffect(() => {
      setProduct(location.state.params)
    },[location])

    return (
        <>
            <IndexNavbar onProduct={onProduct}/>
            <div style={{ margin: '20px 20px'}}>
                {/* <Selections onSelected={onSelected} dataSelected={dataSelected}/> */}
                <Header 
                  product={product} 
                  // dataSelected={dataSelected}
                />
                <About />
                <Contact />
            </div>
            <Footer />
        </>
    )
}

export default PageProduct