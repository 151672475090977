
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import Index from "views/Index.js";
import PageProduct from "views/PageProduct.js"
import PageLogin from "views/PageLogin.js"
import PrivacyPolicy from "views/index-sections/PrivacyPolicy.js";

ReactDOM.hydrate(  
<Suspense fallback="Loading...">
    <BrowserRouter>
      <Switch>
        <Switch>
          <Route path="/website" exact render={(props) => <Index {...props} />} />
          <Route path="/website/privacy-policy" exact render={(props) => <PrivacyPolicy {...props} />} />

          <Route path="/website/product-page" render={(props) => <PageProduct {...props} />} />
          <Route path="/website/page-login" render={(props) => <PageLogin {...props} />} />
          {/* <Redirect to="/index" /> */}
          <Redirect from="/" to="/website" />
        </Switch>
      </Switch>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);

